import { fetcher } from '../../../store/service/helper';
import api from '../../../store/service/api';

export const forgotPasswordService = {
  requestResetLinkFetchAPI(username) {
    const requestUrl = 'rest/resetpassword/sendresetlink';
    const requestParams = {
      email_address: username
    };

    const config = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestParams)
    };

    return fetcher(`${api.expenseBaseUrl}${requestUrl}`, config)
      .then(response => response)
      .catch(error => {
        return error; // This should never be invoked but kept as a safety net
      });
  }
};

export const resetPasswordService = {
  resetPasswordFetchAPI(az_user_id, password, token) {
    const requestUrl = 'rest/resetpassword/reset';
    const requestParams = {
      az_user_id: az_user_id,
      email_address: az_user_id,
      password: password,
      token: token
    };

    const config = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestParams)
    };

    return fetcher(`${api.expenseBaseUrl}${requestUrl}`, config)
      .then(response => response)
      .catch(error => {
        return error; // This should never be invoked but kept as a safety net
      });
  }
};
