import { call, put, take, race, takeLatest, takeLeading, select, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { localizationConfig, localConfig } from '@az/common-configs';
import { isDefined, isEmptyProperty, getAppRouterBasePath, authStorage } from '@az/utility';
import { showAlert } from '@az/components';

import * as actions from '../../../store/actions/actionTypes';
import apiUserLogin from './api-login';
import { basePath, defaultRoute } from '../../../config/appConfig';
import * as selectors from '../../../store/saga/selectors';
import { getNotificationAction, setSessionIsAboutToExpire } from '../../../store/actions/action-coreService';

const { global } = localizationConfig;
const { apiServer } = localConfig;

function clearStorage() {
  authStorage.removeItem('OWASP_CSRFTOKEN');
  authStorage.removeItem('APPZEN_CSRFTOKEN');
  authStorage.removeItem('APPZEN_JWTTOKEN');
  authStorage.removeItem('APPZEN_OKTATOKEN');
  authStorage.removeItem('APPZEN_JWTTOKEN_FIAT');
  authStorage.removeItem('APPZEN_JWTTOKEN_IAT');
  authStorage.removeItem('APPZEN_JWTTOKEN_EXP');
  authStorage.removeItem('session');

  const sessionExpired = sessionStorage.getItem('SessionExpired') === 'true';
  sessionStorage.clear();
  if (sessionExpired) {
    sessionStorage.setItem('SessionExpired', 'true');
  }
}

function loginStart() {
  return {
    type: actions.LOGIN_START,
  };
}

function loginSuccess(data) {
  return {
    type: actions.LOGIN_SUCCESS,
    payload: {
      data,
    },
  };
}

function loginFail(data) {
  return {
    type: actions.LOGIN_FAIL,
    payload: data,
  };
}

function logoutStart() {
  return {
    type: actions.LOGOUT_IN_PROGRESS,
  };
}

function logoutComplete() {
  return {
    type: actions.LOGOUT_COMPLETE,
  }
}

function* submitLoginFetchAPI(payload) {
  const server = apiServer; // for local dev
  let response;
  const reactAppRouterBase = getAppRouterBasePath(basePath);

  try {
    yield put(loginStart());

    response = yield call(apiUserLogin.getTokenFetchAPI, payload.data.username, payload.data.password);

    if (
      isEmptyProperty(response.status) ||
      response.status >= 400
    ) {
      yield put(loginFail(response.msg));

      yield put(showAlert(response.displayMessage || global.notificationMessages.USER_AUTHENTICATION_FAILED, 'error'));
    } else {
      !authStorage.getItem('OWASP_CSRFTOKEN') && authStorage.setItem('OWASP_CSRFTOKEN', response.OWASP_CSRFTOKEN);
      sessionStorage.setItem('OWASP_CSRFTOKEN', response.OWASP_CSRFTOKEN);
      if (!authStorage.getItem('APPZEN_CSRFTOKEN')) {
        authStorage.setItem('APPZEN_JWTTOKEN', response.APPZEN_JWTTOKEN.token || '');
        authStorage.setItem('APPZEN_JWTTOKEN_FIAT', response.APPZEN_JWTTOKEN.fiat || '');
        authStorage.setItem('APPZEN_JWTTOKEN_IAT', response.APPZEN_JWTTOKEN.iat || '');
        authStorage.setItem('APPZEN_JWTTOKEN_EXP', response.APPZEN_JWTTOKEN.exp || '');
      } else {
        sessionStorage.setItem('APPZEN_CSRFTOKEN', authStorage.getItem('APPZEN_CSRFTOKEN'));
      }

      authStorage.removeItem('session');


      yield put({ type: actions.INITIAL_LOAD });
      yield race([
        take(actions.INITIAL_LOAD_SUCCESS),
        take(actions.INITIAL_LOAD_FAIL),
      ]);

      yield put(loginSuccess({ ...response }));
      yield put(showAlert(response.msg || global.notificationMessages.USER_AUTHENTICATION_SUCCESS, 'success'));

      if (response.sso) {
        window.location.assign(server + 'saml/login?idp=' + response['SAML_END_POINT_URL']);
      }

      let { redirectUrl } = payload.data;
      if (isDefined(redirectUrl)) {
        try {
          const url = new URL(redirectUrl);
          url.protocol = window.location.protocol;
          url.host = window.location.host;
          redirectUrl = url.href;
        } catch (e) {
          redirectUrl = 'index.html';
        }
        window.location.assign(redirectUrl);
      } else {
        yield put(push(`${reactAppRouterBase}/${defaultRoute}`));
      }
    }
  } catch (err) {
    yield put(loginFail(err));
    yield put(showAlert(err.displayMessage || global.notificationMessages.USER_AUTHENTICATION_FAILED, 'error'));
  }
}

function* initiateUserLogout() {
  const isUserLoggedOut = yield select(selectors.isUserLoggedOut);

  if (!isUserLoggedOut) {
    yield put(logoutStart());
    let response;
    try {
      response = yield call(apiUserLogin.logoutUser);
      yield put(setSessionIsAboutToExpire(false));
      clearStorage();
      yield put(showAlert(response.msg || global.notificationMessages.LOGOUT_COMPLETE, 'success'));
      yield put(logoutComplete());
      yield put({ type: actions.RESET_APP });

      if (process.env.NODE_ENV === 'production') {
        const logoutRedirectUrl = `${window.location.origin}/console/home.html`; // redirect to angular login page

        window.location.href = (response.data && isDefined(response.data.samlLogoutUrl))
          ? response.data.samlLogoutUrl : logoutRedirectUrl;
      }
    } catch (err) {
      yield put(showAlert(err.displayMessage || global.notificationMessages.LOGOUT_FAILED, 'error'));
    }
  }
}

function* notifyExpiredSession() {
  try {
    yield put(getNotificationAction('error', global.error, global.notificationMessages.SESSION_EXPIRED, true, 2000));
    yield delay(2000);
    yield put({ type: actions.LOGOUT_USER });
  } catch (err) {
    console.log('notifyExpiredSession-err:', err);
  }
}

export const userLoginSaga = [
  takeLatest(actions.LOGIN_SUBMIT, submitLoginFetchAPI),
  takeLatest(actions.LOGOUT_USER, initiateUserLogout),
  takeLeading(actions.NOTIFY_EXPIRED_SESSION, notifyExpiredSession),
];
