import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { localizationConfig } from '@az/common-configs';
import { getAppRouterBasePath, itemsExistInArray, isArrayWithData } from '@az/utility';
import { PersonAddRounded, PeopleOutlineRounded } from '@material-ui/icons';
import { Onboarding } from '@az/icons';
import { basePath } from '../../../config/appConfig';
import SupportDashboardTile from './SupportDashboardTile/SupportDashboardTile';
import './SupportDashboard.scss';

const { support } = localizationConfig;

const reactAppRouterBase = getAppRouterBasePath(basePath);
const dashBoardTileOptions = [
  {
    id: 'create-new-customer',
    text: support.supportDashboard.tiles.CREATE_NEW_CUSTOMER,
    icon: <PersonAddRounded fontSize="inherit" className="support-dashboard-tile__icon" />,
    reDirectURL: `${reactAppRouterBase}/support/create-new-customer`,
  },
  {
    id: 'view-customer-onboarding-status',
    text: support.supportDashboard.tiles.VIEW_CUSTOMER_ONBOARDING_STATUS,
    icon: <Onboarding className="support-dashboard-tile__icon" />,
    reDirectURL: `${reactAppRouterBase}/support/view-customer-onboarding-status`,
  },
  {
    id: 'emulate-user',
    text: support.supportDashboard.tiles.EMULATE_USER,
    icon: <PeopleOutlineRounded className="support-dashboard-tile__icon" />,
    reDirectURL: `${reactAppRouterBase}/support/emulate-user`,
  },
];

function SupportDashboard() {
  const userRoles = useSelector((state) => (
    isArrayWithData(state.core.userDetails.user_roles)
    && state.core.userDetails.user_roles.map((userRole) => userRole.authority)
  ));

  let dashBoardTiles = [];

  if (!itemsExistInArray(['SuperAdmin', 'ConcurPartnerAdmin'], userRoles)) {
    dashBoardTiles = dashBoardTileOptions.filter((dashBoardTile) => dashBoardTile.id !== 'create-new-customer' && dashBoardTile.id !== 'view-customer-onboarding-status');
  } else {
    dashBoardTiles = dashBoardTileOptions;
  }

  const renderDashboardTiles = (tile, index) => (
    <Link to={tile.reDirectURL} key={index}>
      <SupportDashboardTile
        tileIcon={tile.icon}
        tileText={tile.text}
      />
    </Link>
  );

  return (
    <section className="support-dashboard__container">
      {dashBoardTiles.map(renderDashboardTiles)}
    </section>
  );
}
export default SupportDashboard;
