import React from 'react';
import PropTypes from 'prop-types';
import { localizationConfig } from '@az/common-configs';
import { compileTemplate } from '@az/utility';
import { CheckRounded as GreenCheck } from '@material-ui/icons';
import './EmulateSuccess.scss';

const { support } = localizationConfig;

function EmulateSuccess(props) {
  const boldTextFormatter = (key, value) => <strong>{value}</strong>;

  const wrapTemplate = (element, index) => <React.Fragment key={index}>{element}</React.Fragment>;

  const {
    location: {
      state: { userName, customerName },
    },
  } = props;
  return (
    <div className="emulate-success-container">
      <div className="emulate-landing">
        <h1 className="emulate-success-header">
          <GreenCheck className="green-check" />
          {support.emulateUser.emulateSuccess.header.SUCCESS}
        </h1>
        <hr className="horizontal-line" />
        <div className="emulate-success__msg">
          {compileTemplate(
            { userName, customerName },
            support.emulateUser.emulateSuccess.page.NEW_USER_LOGIN_SUCCESS,
            boldTextFormatter,
          ).map(wrapTemplate)}
        </div>
        <span>{support.emulateUser.emulateSuccess.page.NEW_USER_LANDING_MSG}</span>
      </div>
    </div>
  );
}

EmulateSuccess.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      userName: PropTypes.string.isRequired,
      customerName: PropTypes.string.isRequired,
    }),
  }),
};

EmulateSuccess.defaultProps = {
  location: {
    state: {
      userName: '',
      customerName: '',
    },
  },
};

export default EmulateSuccess;
