import { call, put, takeEvery } from 'redux-saga/effects';
import { localizationConfig } from '@az/common-configs';
import { isDefined, isEmptyProperty } from '@az/utility';
import * as actionTypes from '../../../store/actions/actionTypes';
import { createAxiosInstance } from '../../../store/service/helper';
import { userService } from '../api-user-service';
import { getNotificationAction } from '../../../store/actions/action-coreService';

const { global } = localizationConfig;

export const changePasswordStart = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_START
  };
};

export const changePasswordFail = (error) => {
  return {
    type: actionTypes.CHANGE_PASSWORD_FAILED,
    data: error
  };
};

export const changePasswordSuccess = (data) => {
  return {
    type: actionTypes.CHANGE_PASSWORD_SUCCESSFUL,
    data: data
  };
};

function* changePassword(payload) {
  let response;

  try {
    createAxiosInstance();
    yield put(changePasswordStart());
    response = yield call(userService.changePassword, payload.data.currentPassword, payload.data.newPassword);

    if (isEmptyProperty(response) || !isDefined(response.status) || response.status >= 400) {
      const error = response ? response.data ? response.data.msg : response : global.notificationMessages.SERVER_REQUEST_FAILED;

      yield put(changePasswordFail(error));
      yield put(getNotificationAction('error', 'Error', error, true, 5000));
    } else {
      yield put(changePasswordSuccess(response.data));
      yield put(getNotificationAction('success', 'Success', global.notificationMessages.RESET_PASSWORD_SUCCESS, true));
    }
  } catch (e) {
    console.log(e);
  }
}

export const changePasswordSaga = [takeEvery(actionTypes.CHANGE_PASSWORD, changePassword)];
