import { apiEndPointHostNames, localConfig } from '@az/common-configs';
const { apiServer = '' } = localConfig;

const dev = {
  apBaseUrl: `${apiServer}/invoice/`,
  expenseBaseUrl: `${apiServer}/console/`,
  authServiceBaseUrl: '', // Set this to appropriate value for local development
};

const test = {
  apBaseUrl: `${window.location.origin}/invoice/`,
  expenseBaseUrl: `${window.location.origin}/console/`,
  authServiceBaseUrl: apiEndPointHostNames.authServiceBaseUrl(window.location.hostname.toLowerCase()),
};

const prod = {
  apBaseUrl: `${window.location.origin}/invoice/`,
  expenseBaseUrl: `${window.location.origin}/console/`,
  authServiceBaseUrl: apiEndPointHostNames.authServiceBaseUrl(window.location.hostname.toLowerCase()),
};

const api = {
  ...(process.env.NODE_ENV === 'production' ? prod : process.env.NODE_ENV === 'test' ? test : dev),
};

export default api;
