import React from 'react';
import PropTypes from 'prop-types';
import './SupportCardHeader.scss';

function SupportCardHeader(props) {
  const { headerTitle, HeaderIcon, headerIconAltText } = props;

  return (
    <div className="support-card-header">
      <div className="support-card-header__title">
        {headerTitle}
      </div>
      <div className="support-card-header__icon">
        <HeaderIcon className="support-card-header__icon" alt={headerIconAltText} />
      </div>
    </div>
  );
}

SupportCardHeader.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  HeaderIcon: PropTypes.elementType.isRequired,
  headerIconAltText: PropTypes.string.isRequired,
};

export default SupportCardHeader;
