import moment from 'moment';
import momentTz from 'moment-timezone';
import { globalConfig } from '@az/common-configs';

const { inlDateFormat, timeStampFormat } = globalConfig;

export default function displayLocalDateObj(isoDate) {
  const zone = momentTz.tz.guess();
  const dateInUserTimezone = momentTz.utc(isoDate).tz(zone);

  const inlDate = momentTz(dateInUserTimezone).format(inlDateFormat);
  const timestamp = momentTz(dateInUserTimezone).format(timeStampFormat);
  const timezone = momentTz.tz(zone).format('z');

  const localDateObj = {
    inlDate,
    timestamp,
    timezone,
  };

  return localDateObj;
}

export const formatDate = (dateString, format = globalConfig.inlDateFormat) => {
  try {
    return moment(dateString).utc().format(format);
  } catch (e) {
    return dateString;
  }
};
