import { all } from 'redux-saga/effects';
import { changePasswordSaga } from '../../featureComponents/User/ChangePasswordDialog/saga-changePassword';
import { coreServiceSaga } from './saga-coreService';
import { forgotPasswordSaga } from '../../featureComponents/Login/LoginWrapper/saga-forgotPassword';
import { resetPasswordSaga } from '../../featureComponents/Login/LoginWrapper/saga-resetPassword';
import { userLoginSaga } from '../../featureComponents/Login/LoginWrapper/saga-login';
import userSaga from '../../featureComponents/User/saga-user';
import emulateUserSaga from '../../azCoreFeatureComponents/Support/EmulateUser/saga-emulateUser';

export default function* rootSaga() {
  yield all([
    ...changePasswordSaga,
    ...coreServiceSaga,
    ...forgotPasswordSaga,
    ...resetPasswordSaga,
    ...userLoginSaga,
    ...userSaga,
    ...emulateUserSaga,
  ]);
}
