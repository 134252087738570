import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { localizationConfig } from '@az/common-configs';
import { processInputChange } from '@az/utility';
import { Button, Input } from '@az/components';

const { global } = localizationConfig;

class ResetPasswordForm extends Component {
  state = {
    form: {
      newPassword: {
        value: undefined,
        validation: {
          required: true
        },
        valid: false,
        invalidReason: [],
        touched: false
      },
      confirmPassword: {
        value: undefined,
        validation: {
          required: true
        },
        valid: false,
        invalidReason: [],
        touched: false
      }
    },
    formValidation: {
      matching: {
        confirmPassword: 'newPassword'
      }
    },
    formIsValid: false
  };

  static propTypes = {
    onSubmitResetPasswordForm: PropTypes.func.isRequired,
    hideFields: PropTypes.bool
  };

  handleFieldChange = (e) => {
    const form = { ...this.state.form };
    const value = e.target.value;
    const processedResult = processInputChange(form, value, e.target.name, this.state.formValidation);
    this.setState({ form: processedResult.updatedForm, formIsValid: processedResult.formIsValid });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const validatedForm = { ...this.state.form };
    let formIsValid = true;
    const fieldToTestPassword = ['newPassword'];

    for (let field in validatedForm) {
      if (fieldToTestPassword.indexOf(field) > -1) {
        validatedForm[field].validation.isPassword = true;
      }
      const processedResult = processInputChange(validatedForm, validatedForm[field].value, field, this.state.formValidation);
      validatedForm[field] = processedResult.updatedForm[field];
      formIsValid = processedResult.formIsValid && formIsValid;
    }

    this.setState({
      form: validatedForm,
      formIsValid: formIsValid
    }, () => {
      if (this.state.formIsValid) {
        this.props.onSubmitResetPasswordForm(e, this.state.form);
      }
    });

  };

  generateErrorMsg = (fieldObj) => {
    const errorMappingObj = global.formValidationMessages;
    let errorMessage = '';

    if (fieldObj.invalidReason.indexOf('required') > -1) {
      errorMessage += errorMappingObj['REQUIRED'] + '; ';
    }
    if (fieldObj.invalidReason.indexOf('matching') > -1) {
      errorMessage += errorMappingObj['PASSWORDS_DO_NOT_MATCH'] + '; ';
    }
    if (fieldObj.invalidReason.indexOf('isPassword') > -1) {
      errorMessage += errorMappingObj['IS_PASSWORD'] + '; ';
    }
    return errorMessage.slice(0, -2);
  };

  renderNewPassword = () => {
    const newPasswordFieldObj = this.state.form.newPassword;
    return (
      <Input
        elementAttributes={{
          name: 'newPassword',
          type: 'password',
          id: 'newPassword',
          autoFocus: true,
          value: newPasswordFieldObj.value || '',
          autoComplete: 'new-password'
        }}
        label="New Password"
        onChange={this.handleFieldChange}
        theme="bottom-only lock"
        isValid={newPasswordFieldObj.valid}
        isTouched={newPasswordFieldObj.touched}
        isRequired={newPasswordFieldObj.validation.required}
        errorMessage={this.generateErrorMsg(newPasswordFieldObj)}
      />
    );
  };

  renderConfirmPassword = () => {
    const confirmPasswordFieldObj = this.state.form.confirmPassword;
    return (
      <Input
        elementAttributes={{
          name: 'confirmPassword',
          type: 'password',
          id: 'confirmPassword',
          value: confirmPasswordFieldObj.value || '',
          autoComplete: 'new-password'
        }}
        label="Comfirm Password"
        onChange={this.handleFieldChange}
        theme="bottom-only lock"
        isValid={confirmPasswordFieldObj.valid}
        isTouched={confirmPasswordFieldObj.touched}
        isRequired={confirmPasswordFieldObj.validation.required}
        errorMessage={this.generateErrorMsg(confirmPasswordFieldObj)}
      />
    );
  };

  renderFormBody = () => (
    <React.Fragment>
      <div className="form__body">
        <div className="loginWrapper__form-field">
          {this.renderNewPassword()}
        </div>
        <div className="loginWrapper__form-field">
          {this.renderConfirmPassword()}
        </div>
      </div>

      <div className="form__footer">
        <Button
          elementAttributes={{ type: "submit" }}
          classes="loginWrapper__button button--brand button--round"
        >{global.buttonText.RESET_PASSWORD}</Button>
      </div>
    </React.Fragment>
  );

  render() {
    return (
      <form className="form form--single loginWrapper__form forgot-password-form" noValidate onSubmit={this.handleSubmit}>
        {this.props.hideFields ? null : this.renderFormBody()}
        <div className="form__insert">
          <Link to="/login" className="form__link">Login here </Link> <span className="form__text">&nbsp; with your password</span>
        </div>
      </form>
    );
  }
}

export default ResetPasswordForm;
