import { updateObject } from '@az/utility';
import * as actions from '../../../store/actions/actionTypes';

const initialState = {
  requestResetInProgress: false,
  requestResetComplete: false,
  error: null
};

const requestStart = (state, action) => {
  return updateObject(state, {
    requestResetInProgress: true,
    error: null
  });
};

const requestFail = (state, action) => {
  return updateObject(state, {
    requestResetInProgress: false,
    requestResetComplete: true,
    error: action.error
  });
};

const requestSuccess = (state, action) => {
  return updateObject(state, {
    requestResetInProgress: false,
    requestResetComplete: true,
    error: null
  });
};

const requestPasswordResetReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST_PASSWORD_RESET_START:
      return requestStart(state, action);
    case actions.REQUEST_PASSWORD_RESET_FAILED:
      return requestFail(state, action);
    case actions.REQUEST_PASSWORD_RESET_SUCCESSFUL:
      return requestSuccess(state, action);
    default:
      return state;
  }
};

export default requestPasswordResetReducer;