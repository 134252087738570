import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import { IntlProvider } from 'react-intl';
import 'core-js/stable'; // polyfill for ES2015+ features. https://babeljs.io/docs/en/babel-polyfill, https://www.npmjs.com/package/core-js
import 'regenerator-runtime/runtime'; // polyfill for ES2015+ features. https://babeljs.io/docs/en/babel-polyfill, https://www.npmjs.com/package/core-js
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import en from './messages/en';

import App from './App/App';
import { unregister } from './registerServiceWorker';
import store, { history } from './store';
import './assets/css/style.scss';

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale="en" messages={en}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </IntlProvider>
  </Provider>,
  document.getElementById('root'),
);

unregister();
