import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { localizationConfig } from '@az/common-configs';
import { processInputChange } from '@az/utility';
import { Button, Input } from '@az/components';

const { global } = localizationConfig;

class ForgotPasswordForm extends Component {
  state = {
    form: {
      username: {
        value: undefined,
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
    },
    formIsValid: false
  };

  static propTypes = {
    onSubmitForgotPasswordForm: PropTypes.func.isRequired,
    hideFields: PropTypes.bool
  };

  handleFieldChange = (e) => {
    const form = {...this.state.form};
    const value = e.target.value;
    const processedResult = processInputChange(form, value, e.target.name);
    this.setState({ form: processedResult.updatedForm, formIsValid: processedResult.formIsValid });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.onSubmitForgotPasswordForm(e, this.state.form);
  };

  renderUsername = () => {
    return (
      <Input
        elementAttributes={{
          name: 'username',
          type: 'text',
          id: 'username',
          autoFocus: true,
          value: this.state.form.username.value || ''
        }}
        label="Email Address or User Id"
        onChange={this.handleFieldChange}
        theme="bottom-only mail"
        isValid={this.state.form.username.valid}
        isTouched={this.state.form.username.touched}
        isRequired={true}
      />
    );
  };

  renderFormBody = () => (
    <React.Fragment>
      <div className="form__body">
        <div className="loginWrapper__form-field">
          {this.renderUsername()}
        </div>
      </div>

      <div className="form__footer">
        <Button
          elementAttributes={{ type: "submit" }}
          classes="loginWrapper__button button--brand button--round"
        >{global.buttonText.SEND_EMAIL}</Button>
      </div>
    </React.Fragment>
  );

  render() {
    return (
      <form className="form form--single loginWrapper__form forgot-password-form" noValidate onSubmit={this.handleSubmit}>
      {this.props.hideFields ? null : this.renderFormBody()}
      <div className="form__insert">
        <Link to="/login" className="form__link">Login here </Link> <span className="form__text">&nbsp; with your password</span>
      </div>
      </form>
    );
  }
}

export default ForgotPasswordForm;
