import { updateObject } from '@az/utility';
import * as actions from '../../../store/actions/actionTypes';

const initialState = {
  changePasswordInProgress: false,
  changePasswordComplete: false,
  error: null
};

const updatePasswordReseet = (state, action) => {
  return updateObject(state, {
    changePasswordInProgress: false,
    changePasswordComplete: false,
    error: null
  });
};

const requestStart = (state, action) => {
  return updateObject(state, {
    changePasswordInProgress: true,
    error: null
  });
};

const requestFail = (state, action) => {
  return updateObject(state, {
    changePasswordInProgress: false,
    changePasswordComplete: true,
    error: action.data
  });
};

const requestSuccess = (state, action) => {
  return updateObject(state, {
    changePasswordInProgress: false,
    changePasswordComplete: true,
    error: null
  });
};

const updatePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CHANGE_PASSWORD_RESET:
      return updatePasswordReseet(state, action);
    case actions.CHANGE_PASSWORD_START:
      return requestStart(state, action);
    case actions.CHANGE_PASSWORD_FAILED:
      return requestFail(state, action);
    case actions.CHANGE_PASSWORD_SUCCESSFUL:
      return requestSuccess(state, action);
    default: return state;
  }
};

export default updatePasswordReducer;