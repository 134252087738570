import { fetcher } from '../../store/service/helper';
import api from '../../store/service/api';

const apiUser = {
  deleteUserRoles(azUserId, roleIds) {
    const config = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        az_user_id: azUserId,
        role_ids: roleIds,
      }),
    };
    const url = `${api.apBaseUrl}rest/v1/users/roles`;
    return fetcher(url, config, false);
  },
  getUsers(limit, offset, format, orderBy, params) {
    const payload = {
      limit,
      offset,
      order_by: orderBy,
      response_format: format,
    };

    const formattedPayload = { ...payload, ...params };

    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formattedPayload),
    };
    const url = `${api.apBaseUrl}rest/v1/users`;
    return fetcher(url, config);
  },
  getRolesByProduct(product = 'all') {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const url = `${api.apBaseUrl}rest/v1/users/roles?product_id=${product}`;
    return fetcher(url, config, false);
  },
  updateOrganizations(azUserId, defaultOrgId, organizations) {
    const devMode = false;
    if (devMode) {
      return new Promise((resolve) => resolve({ status: 200, data: { msg: 'Success' } }));
    }

    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        az_user_id: azUserId,
        default_org: defaultOrgId,
        ap_organizations: organizations,
      }),
    };
    const url = `${api.apBaseUrl}rest/v1/users/organizations`;
    return fetcher(url, config, false);
  },
  updateUserRoles(azUserId, roleIds) {
    const config = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        az_user_id: azUserId,
        role_ids: roleIds,
      }),
    };
    const url = `${api.apBaseUrl}rest/v1/users/roles`;
    return fetcher(url, config, false);
  },
};

export default apiUser;
