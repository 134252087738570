// UI
export const SET_FULLSCREEN_MODE_ON = 'SET_FULLSCREEN_MODE_ON';
export const SET_FULLSCREEN_MODE_OFF = 'SET_FULLSCREEN_MODE_OFF';
export const TOGGLE_FULLSCREEN_MODE = 'TOGGLE_FULLSCREEN_MODE';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

// Login
export const EMPTY_LOGIN_ID = 'EMPTY_LOGIN_ID';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_SUBMIT = 'LOGIN_SUBMIT';
export const NOTIFY_EXPIRED_SESSION = 'NOTIFY_EXPIRED_SESSION';

// Core
export const INITIAL_LOAD_START = 'INITIAL_LOAD_START';
export const INITIAL_LOAD_SUCCESS = 'INITIAL_LOAD_SUCCESS';
export const INITIAL_LOAD_FAIL = 'INITIAL_LOAD_FAIL';
export const INITIAL_LOAD = 'INITIAL_LOAD';
export const SWITCH_USER = 'SWITCH_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_IN_PROGRESS = 'LOGOUT_IN_PROGRESS';
export const LOGOUT_COMPLETE = 'LOGOUT_COMPLETE';
export const REQUEST_RESET_SESSION_START = 'REQUEST_RESET_SESSION_START';
export const REQUEST_RESET_SESSION = 'REQUEST_RESET_SESSION';
export const REQUEST_RESET_SESSION_COMPLETE = 'REQUEST_RESET_SESSION_COMPLETE';
export const RESET_APP = 'RESET_APP';
export const START_SESSION_TIMER = 'START_SESSION_TIMER';
export const SET_CUSTOMER_DEFAULT_SESSION_TIME_IN_SEC = 'SET_CUSTOMER_DEFAULT_SESSION_TIME_IN_SEC';
export const SET_SESSION_IS_ABOUT_TO_EXPIRE = 'SET_SESSION_IS_ABOUT_TO_EXPIRE';

// SSO
export const SSO_SUBMIT = 'SSO_SUBMIT';

// Request Password Reset
export const REQUEST_PASSWORD_RESET_START = 'REQUEST_PASSWORD_RESET_START';
export const REQUEST_PASSWORD_RESET_FAILED = 'REQUEST_PASSWORD_RESET_FAILED';
export const REQUEST_PASSWORD_RESET_SUCCESSFUL = 'REQUEST_PASSWORD_RESET_SUCCESSFUL';
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';

// Reset password
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const RESET_PASSWORD_SUCCESSFUL = 'RESET_PASSWORD_SUCCESSFUL';
export const RESET_PASSWORD = 'RESET_PASSWORD';

// Change password
export const CHANGE_PASSWORD_RESET = 'CHANGE_PASSWORD_RESET';
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const CHANGE_PASSWORD_SUCCESSFUL = 'CHANGE_PASSWORD_SUCCESSFUL';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

// Notifications
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const HIDE_ALL_NOTIFICATIONS = 'HIDE_ALL_NOTIFICATIONS';

// AP Reports
export const GET_AP_REPORT_TYPES = 'GET_AP_REPORT_TYPES';
export const GET_AP_REPORT_TYPES_SUCCESS = 'GET_AP_REPORT_TYPES_SUCCESS';

// Contract Workbench
export const GET_CONTRACT_FILTERS = 'GET_CONTRACT_FILTERS';
export const GET_CONTRACT_FILTERS_SUCCESS = 'GET_CONTRACT_FILTERS_SUCCESS';
export const GET_CONTRACTS = 'GET_CONTRACTS';
export const GET_CONTRACTS_SUCCESS = 'GET_CONTRACTS_SUCCESS';

// Contract Details
export const ACTION_CONTRACT = 'ACTION_CONTRACT';
export const GET_CONTRACT_DETAILS = 'GET_CONTRACT_DETAILS';
export const GET_CONTRACT_DETAILS_SUCCESS = 'GET_CONTRACT_DETAILS_SUCCESS';
export const GET_PRICING_TABLE_HEADER_METADATA = 'GET_PRICING_TABLE_HEADER_METADATA';
export const GET_PRICING_TABLE_METADATA_SUCCESS = 'GET_PRICING_TABLE_METADATA_SUCCESS';
export const RERUN_CONTRACT_AUDIT = 'RERUN_CONTRACT_AUDIT';
export const SAVE_PRICING_TABLE = 'SAVE_PRICING_TABLE';
export const SAVE_PRICING_TABLE_SUCCESS = 'SAVE_PRICING_TABLE_SUCCESS';
export const UPDATE_CONTRACT_TERM = 'UPDATE_CONTRACT_TERM';
export const UPDATE_CONTRACT_TERM_SUCCESS = 'UPDATE_CONTRACT_TERM_SUCCESS';

// Invoice Workbench
export const GET_INVOICE_FILTERS = 'GET_INVOICE_FILTERS';
export const GET_INVOICE_FILTERS_SUCCESS = 'GET_INVOICE_FILTERS_SUCCESS';
export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICE_WORKBENCH_TILES = 'GET_INVOICE_WORKBENCH_TILES';
export const GET_INVOICE_WORKBENCH_TILES_SUCCESS = 'GET_INVOICE_WORKBENCH_TILES_SUCCESS';

// Invoice Details
export const ACTION_INVOICE = 'ACTION_INVOICE';
export const GET_DUPLICATE_INVOICE_DETAILS = 'GET_DUPLICATE_INVOICE_DETAILS';
export const GET_DUPLICATE_INVOICE_DETAILS_SUCCESS = 'GET_DUPLICATE_INVOICE_DETAILS_SUCCESS';
export const GET_DUPLICATE_INVOICE_HEADER_AUDIT_RESULTS = 'GET_DUPLICATE_INVOICE_HEADER_AUDIT_RESULTS';
export const GET_DUPLICATE_INVOICE_HEADER_AUDIT_RESULTS_SUCCESS = 'GET_DUPLICATE_INVOICE_HEADER_AUDIT_RESULTS_SUCCESS';
export const GET_INVOICE_HEADER_AUDIT_RESULTS = 'GET_INVOICE_HEADER_AUDIT_RESULTS';
export const GET_INVOICE_HEADER_AUDIT_RESULTS_SUCCESS = 'GET_INVOICE_HEADER_AUDIT_RESULTS_SUCCESS';
export const GET_INVOICE_EXCLUDE_REASONS = 'GET_INVOICE_EXCLUDE_REASONS';
export const GET_INVOICE_EXCLUDE_REASONS_SUCCESS = 'GET_INVOICE_EXCLUDE_REASONS_SUCCESS';
export const GET_LOGICAL_INVOICE_DETAILS = 'GET_LOGICAL_INVOICE_DETAILS';
export const GET_LOGICAL_INVOICE_DETAILS_SUCCESS = 'GET_LOGICAL_INVOICE_DETAILS_SUCCESS';
export const GET_INVOICE_AUDIT_HISTORY = 'GET_INVOICE_AUDIT_HISTORY';
export const GET_INVOICE_AUDIT_HISTORY_SUCCESS = 'GET_INVOICE_AUDIT_HISTORY_SUCCESS';
export const RESET_DUPLICATE_INVOICE_DETAILS_DATA = 'RESET_DUPLICATE_INVOICE_DETAILS_DATA';

// Supplier Details
export const GET_SUPPLIER_DETAILS = 'GET_SUPPLIER_DETAILS';
export const GET_SUPPLIER_DETAILS_SUCCESS = 'GET_SUPPLIER_DETAILS_SUCCESS';
export const GET_SUPPLIER_CONTRACTS = 'GET_SUPPLIER_CONTRACTS';
export const GET_SUPPLIER_CONTRACTS_SUCCESS = 'GET_SUPPLIER_CONTRACTS_SUCCESS';
export const GET_SUPPLIER_INVOICES = 'GET_SUPPLIER_INVOICES';
export const GET_SUPPLIER_INVOICES_SUCCESS = 'GET_SUPPLIER_INVOICES_SUCCESS';
export const RESET_WORKBENCH_FROM_SUPPLIER_DETAILS = 'RESET_WORKBENCH_FROM_SUPPLIER_DETAILS';

// Touchless AP Workbench
export const GET_TOUCHLESS_INVOICES = 'GET_TOUCHLESS_INVOICES';
export const GET_TOUCHLESS_INVOICE_DETAILS = 'GET_TOUCHLESS_INVOICE_DETAILS';
export const GET_TOUCHLESS_INVOICES_SUCCESS = 'GET_TOUCHLESS_INVOICES_SUCCESS';
export const GET_TOUCHLESS_INVOICE_DETAILS_SUCCESS = 'GET_TOUCHLESS_INVOICE_DETAILS_SUCCESS';
export const GET_TOUCHLESS_UNPROCESSED_INVOICES = 'GET_TOUCHLESS_UNPROCESSED_INVOICES';
export const GET_TOUCHLESS_UNPROCESSED_INVOICES_SUCCESS = 'GET_TOUCHLESS_UNPROCESSED_INVOICES_SUCCESS';
export const UPDATE_TOUCHLESS_WORKBENCH_TAB = 'UPDATE_TOUCHLESS_WORKBENCH_TAB';
export const GET_TOUCHLESS_INVOICE_HISTORY = 'GET_TOUCHLESS_INVOICE_HISTORY';
export const GET_TOUCHLESS_INVOICE_HISTORY_SUCCESS = 'GET_TOUCHLESS_INVOICE_HISTORY_SUCCESS';

// Emulate User
export const EMULATE_USER = 'EMULATE_USER';

// User Mangement
export const GET_ROLES_BY_PRODUCT = 'GET_ROLES_BY_PRODUCT';
export const GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS';
export const GET_ALL_AP_ROLES_SUCCESS = 'GET_ALL_AP_ROLES_SUCCESS';
