import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { localizationConfig } from '@az/common-configs';
import { getAppRouterBasePath, isDefined, isArrayWithData } from '@az/utility';
import { PeopleOutlineRounded } from '@material-ui/icons';
import { basePath } from '../../../config/appConfig';
import * as actions from '../../../store/actions/actionTypes';
import { Button } from '@az/components';
import SupportCard from '../SupportCoreComponents/SupportCard/SupportCard';
import apiEmulateUser from './api-emulateUser';
import './EmulateUser.scss';

const { support, global } = localizationConfig;

export default function EmulateUser() {
  const [customerSelected, setCustomerSelected] = useState([]);
  const [userSelected, setUserSelected] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [usersForCustomer, setUsersForCustomer] = useState([]);

  const dispatch = useDispatch();

  const reactAppRouterBase = getAppRouterBasePath(basePath);

  const handleCustomerSelectedChange = (customer) => {
    setCustomerSelected(customer);
    if (!isArrayWithData(customer)) {
      setCustomers([]);
      setUserSelected([]);
      setUsersForCustomer([]);
    }
  };

  const handleUserSelectedChange = (user) => {
    setUserSelected(user);
    if (!isArrayWithData(user)) {
      setUsersForCustomer([]);
    }
  };

  const handleCustomersQuery = (query) => {
    if (isDefined(query)) {
      return Promise.resolve(apiEmulateUser.getCustomers({ search: query }, dispatch)
        .then((queryCustomers) => {
          setCustomers(queryCustomers);
        }));
    }
    return Promise.resolve(setCustomers([]));
  };

  const handleUsersForCustomerQuery = (query) => {
    if (isDefined(query)) {
      return Promise.resolve(apiEmulateUser.getUsers({
        search: query,
        customer: customerSelected[0],
      }, dispatch)
        .then((queryUsersForCustomer) => {
          setUsersForCustomer(queryUsersForCustomer);
        }));
    }
    return Promise.resolve(setUsersForCustomer([]));
  };

  const handleEmulateUser = (event) => {
    event.preventDefault();
    dispatch({
      type: actions.EMULATE_USER,
      payload: {
        user: userSelected[0],
        customer: customerSelected[0],
      },
    });
  };

  const renderActionButtons = () => (
    <div className="emulate-user__action-buttons">
      <Link to={`${reactAppRouterBase}/support/dashboard`}>
        <Button classes="button--no-outline">{`< ${global.BACK}`}</Button>
      </Link>
      <Button isDisabled={!isArrayWithData(customerSelected) || !isArrayWithData(userSelected)} classes="button--brand" type="submit">{global.buttonText.SUBMIT}</Button>
    </div>
  );

  const emulateUserFormFields = [{
    label: support.emulateUser.form.CUSTOMER_NAME,
    key: 'customer',
    handleChange: handleCustomerSelectedChange,
    handleQueryForInput: handleCustomersQuery,
    value: customerSelected,
    options: customers,
    isDisabled: false,
  }, {
    label: support.emulateUser.form.USER_NAME,
    key: 'user',
    handleChange: handleUserSelectedChange,
    handleQueryForInput: handleUsersForCustomerQuery,
    value: userSelected,
    options: usersForCustomer,
    isDisabled: !isArrayWithData(customerSelected),
  }];

  return (
    <form id="emulateUserForm" name="Emulate User Form" className="emulate-user-container" onSubmit={handleEmulateUser}>
      <SupportCard
        formFields={emulateUserFormFields}
        HeaderIcon={PeopleOutlineRounded}
        headerTitle={support.emulateUser.header.EMULATE_USER}
        headerIconAltText={support.emulateUser.header.EMULATE_ICON_ALT_TEXT}
      />
      {renderActionButtons()}
    </form>
  );
}
