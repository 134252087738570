import React from 'react';
import PropTypes from 'prop-types';
import SupportCardHeader from './SupportCardHeader/SupportCardHeader';
import SupportCardFormField from './SupportCardFormField/SupportCardFormField';
import './SupportCard.scss';

function SupportCard(props) {
  const renderSupportCardHeader = (headerTitle, HeaderIcon, headerIconAltText) => (
    <SupportCardHeader
      headerTitle={headerTitle}
      HeaderIcon={HeaderIcon}
      headerIconAltText={headerIconAltText}
    />
  );

  const renderSupportCardBody = (formField, index) => {
    const {
      label, key = index, value, options, isDisabled, handleChange, handleQueryForInput,
    } = formField;

    return (
      <SupportCardFormField
        label={label}
        key={key}
        value={value}
        options={options}
        isDisabled={isDisabled}
        handleChange={handleChange}
        handleQueryForInput={handleQueryForInput}
      />
    );
  };

  const {
    formFields, headerTitle, HeaderIcon, headerIconAltText,
  } = props;

  return (
    <div className="support-card">
      {renderSupportCardHeader(headerTitle, HeaderIcon, headerIconAltText)}
      {formFields.map(renderSupportCardBody)}
    </div>
  );
}

SupportCard.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  HeaderIcon: PropTypes.elementType.isRequired,
  headerIconAltText: PropTypes.string.isRequired,
  formFields: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    key: PropTypes.string,
    value: PropTypes.array,
    options: PropTypes.array,
    isDisabled: PropTypes.bool,
    handleChange: PropTypes.func,
    handleQueryForInput: PropTypes.func,
  })),
};

SupportCard.defaultProps = {
  formFields: [],
};

export default SupportCard;
