import axios from 'axios';
import 'whatwg-fetch';
import { authStorage } from '@az/utility';

import api from './api';
import store from '../../store';
import * as actions from '../actions/actionTypes';
import * as actionCreators from '../actions/action-coreService';

export let instance;
export let authInstance;
export let publicInstance;

export function createPublicAxiosInstance() {
  publicInstance = axios.create({
    baseURL: api.expenseBaseUrl,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  publicInstance.interceptors.request.use((config) => config);

  publicInstance.interceptors.response.use(response => {
    return response;
  }, error => {
    return Promise.reject(error);
  });

  return publicInstance;
}

export function createAxiosInstance() {
  instance = axios.create({
    baseURL: api.expenseBaseUrl,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  instance.defaults.withCredentials = true;

  instance.interceptors.request.use(function (config) {
    config.headers = {
      ...config.headers,
      'x-az-uuid': "desktopbrowser",
      'x-az-device-type': "desktop",
      'x-az-app-id': "1006",
      'x-az-app-version': "3.0",
      'OWASP_CSRFTOKEN': authStorage.getItem('OWASP_CSRFTOKEN'),
      'APPZEN_CSRFTOKEN': authStorage.getItem('APPZEN_CSRFTOKEN'),
      'Authorization': 'Bearer '.concat(authStorage.getItem('APPZEN_JWTTOKEN')),
      'X-Requested-With': 'XMLHttpRequest',
    };
    return config;
  });

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response) {
        return Promise.resolve(error.response);
      } else {
        return Promise.resolve(error);
      }
    }
  );

  return instance;
}

export function createAxiosAuthInstance() {
  authInstance = axios.create({
    baseURL: api.expenseBaseUrl,
  });

  return authInstance;
}

function parseResponse(response) {
  const contentType = response.headers.get('content-type');

  if (contentType) {
    if (contentType.includes('application/json')) {
      return response.json();
    }

    if (contentType.includes('text/')) {
      return response.text();
    }

    if (contentType.includes('image/') || contentType.includes('application/pdf') || contentType.includes('application/zip')) {
      return response.blob();
    }

    // fallback for other types. Errors will be caught by the exception handler.
    return response.json();
  }
  throw new TypeError('Returned content-type is not in expected format.');
}

export function fetcher(url, config, showLoading = true, isToResetTimer = true) {
  let status;
  let headers;

  const newHeaders = {
    'x-az-app-id': '1006',
    'OWASP_CSRFTOKEN': authStorage.getItem('OWASP_CSRFTOKEN'),
    'APPZEN_CSRFTOKEN': authStorage.getItem('APPZEN_CSRFTOKEN'),
    'Authorization': 'Bearer '.concat(authStorage.getItem('APPZEN_JWTTOKEN')),
    'x-az-uuid': 'desktopbrowser', // does not seem to be mandatory
    'x-az-device-type': 'desktop', // does not seem to be mandatory
    'x-az-app-version': '3.0', // does not seem to be mandatory
    'X-Requested-With': 'XMLHttpRequest',
  };

  let newConfig = {
    mode: 'cors',
    credentials: 'include',
    headers: Object.assign({}, config.headers, newHeaders),
  };

  newConfig = Object.assign({}, config, newConfig);

  if (showLoading) {
    store.dispatch({ type: actions.SHOW_LOADING });
  }
  if (isToResetTimer) {
    store.dispatch(actionCreators.startSessionTimer('helper'));
  }

  return fetch(url, newConfig)
    .then((response) => {
      if (showLoading) {
        store.dispatch({ type: actions.HIDE_LOADING });
      }
      status = response.status;
      headers = response.headers;

      if (!newConfig.disableAuthCheck && response.status === 401) {
        store.dispatch({ type: actions.NOTIFY_EXPIRED_SESSION });
      }
      return parseResponse(response);
    })
    .then((response) => ({
      status,
      data: response,
      headers,
    }))
    .catch((error) => {
      if (showLoading) {
        store.dispatch({ type: actions.HIDE_LOADING });
      }
      return {
        status,
        data: error,
      };
    });
}
