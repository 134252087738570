import React from 'react';
import ReactDOM from 'react-dom';
import {
  AzDialog,
  AzDialogCloseButton,
  AzDialogContent,
  AzDialogActions,
  AzDialogTitle,
  Button,
} from '@az/components';

const UserConfirmation = (message, callback) => {
  const parsedMessage = JSON.parse(message);
  const container = document.createElement('div');
  container.setAttribute('custom-confirmation-navigation', '');
  document.body.appendChild(container);

  const closeModal = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    callback(callbackState);
  };

  ReactDOM.render(
    <AzDialog
      open
      handleClose={() => closeModal(false)}
      other={{
        maxWidth: 'sm',
        fullWidth: true,
      }}
    >
      <AzDialogCloseButton handleClose={() => closeModal(false)} />
      <AzDialogTitle>
        <b>{parsedMessage.titleText}</b>
      </AzDialogTitle>
      <AzDialogContent>{parsedMessage.messageText}</AzDialogContent>
      <AzDialogActions>
        <Button dataAzLabel="Cancel" classes="button--no-outline" clicked={() => closeModal(false)}>{parsedMessage.cancelText}</Button>
        <Button dataAzLabel="Confirm" classes="button--brand" clicked={() => closeModal(true)}>{parsedMessage.confirmText}</Button>
      </AzDialogActions>
    </AzDialog>,
    container,
  );
};

export default UserConfirmation;
