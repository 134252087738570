import React from 'react';
import PropTypes from 'prop-types';

function SupportDashboardTile(props) {
  const {
    tileIcon,
    tileText,
  } = props;

  return (
    <div className="support-dashboard__tile">
      {tileIcon}
      <div className="support-dashboard-tile__text">
        {tileText}
      </div>
    </div>
  );
}

SupportDashboardTile.propTypes = {
  tileIcon: PropTypes.element.isRequired,
  tileText: PropTypes.string.isRequired,
};

export default SupportDashboardTile;
