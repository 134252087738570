import { updateObject } from '@az/utility';
import * as actions from '../actions/actionTypes';

const initialState = {
  isInFullscreenMode: false,
  apiCallsCount: 0,
  isLoading: false,
};

const hideLoading = (state) => {
  const apiCallsCount = state.apiCallsCount > 0 ? state.apiCallsCount - 1 : state.apiCallsCount;
  return updateObject(state, {
    isLoading: apiCallsCount > 0,
    apiCallsCount,
  });
};

const setFullscreenModeOn = (state, action) => {
  return updateObject(state, {
    isInFullscreenMode: true,
  });
};

const setFullscreenModeOff = (state, action) => {
  return updateObject(state, {
    isInFullscreenMode: false,
  });
};

const showLoading = (state) => updateObject(state, {
  isLoading: true,
  apiCallsCount: state.apiCallsCount + 1,
});

const toggleFullscreenMode = (state, action) => {
  if (state.isInFullscreenMode) {
    return setFullscreenModeOff(state, action);
  }
  return setFullscreenModeOn(state, action);
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.HIDE_LOADING:
      return hideLoading(state);
    case actions.SET_FULLSCREEN_MODE_ON:
      return setFullscreenModeOn(state, action);
    case actions.SET_FULLSCREEN_MODE_OFF:
      return setFullscreenModeOff(state, action);
    case actions.SHOW_LOADING:
      return showLoading(state);
    case actions.TOGGLE_FULLSCREEN_MODE:
      return toggleFullscreenMode(state, action);
    default: return state;
  }
};

export default uiReducer;
