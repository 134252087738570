import { generateQueryURLWithParams } from '@az/utility';

import { fetcher } from '../../store/service/helper';
import api from '../../store/service/api';

const apiAAAReports = {
  getAAAReports({ length, start, orgId }) {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const url = `${api.expenseBaseUrl}rest/reports/getreports`;
    const params = {
      length,
      start,
      orgId,
      report_category: 'REPORT_CATEGORY_AAA',
    };

    const request = generateQueryURLWithParams(url, params);
    return fetcher(request, config);
  },
  refreshReport(reportId) {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const url = `${api.expenseBaseUrl}rest/reports/getreport?reportId=${reportId}`;

    return fetcher(url, config);
  },
  getInvoiceStateChoices() {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const url = `${api.expenseBaseUrl}rest/reports/getinvoicestatelist`;
    return fetcher(url, config);
  },
  retryReport(reportId) {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: null,
    };
    const url = `${api.expenseBaseUrl}rest/v1/expense/generate_reports/${reportId}/retry`;

    return fetcher(url, config);
  },
  getUserOrganizations() {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const url = `${api.expenseBaseUrl}rest/v1/organizations`;
    return fetcher(url, config);
  },
  createAAAReport(payload) {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };
    const url = `${api.expenseBaseUrl}rest/v1/expense/generate_reports/AAAByDateRangeReport`;
    return fetcher(url, config, false);
  },
  getAPlistingreports(orgId) {
    const config = {
      method: 'GET',
    };
    const url = `${api.expenseBaseUrl}rest/reports/getexpenselistingreports?orgId=${orgId}`;
    return fetcher(url, config);
  },
  generateEmployeeDetailsAPReport() {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    };
    const url = `${api.expenseBaseUrl}rest/v1/expense/generate_reports/EmployeeDetailsReportAAA`;
    return fetcher(url, config, false);
  },
  downloadAAAReport(s3Key) {
    const config = {
      method: 'GET',
      responseType: 'arraybuffer',
    };
    const url = `${api.expenseBaseUrl}rest/reports/download?inputFileKey=${s3Key}`;
    return fetch(url, config).then((res) => res.blob());
  },
};

export default apiAAAReports;
