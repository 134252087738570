import { updateObject } from '@az/utility';
import * as actions from '../../../store/actions/actionTypes';

const initialState = {
  resetPasswordInProgress: false,
  resetComplete: false,
  error: null
};

const requestStart = (state, action) => {
  return updateObject(state, {
    resetPasswordInProgress: true,
    error: null
  });
};

const requestFail = (state, action) => {
  return updateObject(state, {
    resetPasswordInProgress: false,
    resetComplete: true,
    error: action.error
  });
};

const requestSuccess = (state, action) => {
  return updateObject(state, {
    resetPasswordInProgress: false,
    resetComplete: true,
    error: null
  });
};

const resetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.RESET_PASSWORD_START:
      return requestStart(state, action);
    case actions.RESET_PASSWORD_FAILED:
      return requestFail(state, action);
    case actions.RESET_PASSWORD_SUCCESSFUL:
      return requestSuccess(state, action);
    default:
      return state;
  }
};

export default resetPasswordReducer;