import { call, put, takeEvery } from 'redux-saga/effects';
import { localizationConfig } from '@az/common-configs';
import { isEmptyProperty, isDefined } from '@az/utility';
import { showAlert } from '@az/components';
import * as actionTypes from '../../../store/actions/actionTypes';
import { createPublicAxiosInstance } from '../../../store/service/helper';
import { forgotPasswordService } from './api-password-manage';

const { global } = localizationConfig;

export const requestResetPasswordStart = () => {
  return {
    type: actionTypes.REQUEST_PASSWORD_RESET_START
  };
};

export const requestResetPasswordFail = (error) => {
  return {
    type: actionTypes.REQUEST_PASSWORD_RESET_FAILED,
    payload: error
  };
};

export const requestResetPasswordSuccess = (data) => {
  return {
    type: actionTypes.REQUEST_PASSWORD_RESET_SUCCESSFUL,
    payload: data
  };
};

function* submitForgotPassword(payload) {
  let response;

  try {
    createPublicAxiosInstance();
    yield put(requestResetPasswordStart());

    response = yield call(forgotPasswordService.requestResetLinkFetchAPI, payload.data.username);

    if (isEmptyProperty(response) || !isDefined(response.status) || response.status >= 400) {
      const error = response ? response.data ? response.data : response : global.notificationMessages.SERVER_REQUEST_FAILED;
      yield put(requestResetPasswordFail(error));
      yield put(showAlert(global.notificationMessages.SERVER_REQUEST_FAILED, 'error'));
    } else {
      yield put(requestResetPasswordSuccess(response.data));
      yield put(showAlert(response.data.msg, 'success'));
    }
  } catch (e) {
    console.log(e);
  }

}

export const forgotPasswordSaga = [takeEvery(actionTypes.REQUEST_PASSWORD_RESET, submitForgotPassword)];
