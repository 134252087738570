import { authInstance, fetcher } from '../../../store/service/helper';
import { authStorage } from '@az/utility';
import qs from 'qs';
import 'whatwg-fetch';
import api from '../../../store/service/api';
import { apiEndPointHostNames } from '@az/common-configs';

let apiUserLogin = {
  getTokenFetchAPI(username, password, rememberMe = false) {
    const requestParams = {
      'j_username': username,
      'j_password': password,
      '_spring_security_remember_me': rememberMe,
    };
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-az-uuid': 'desktopbrowser', // does not seem to be mandatory
      'x-az-device-type': 'desktop', // does not seem to be mandatory
      'x-az-app-id': '1006',
      'x-az-app-version': '1.0', // does not seem to be mandatory
      'spring_security_remember_me': true,
      'X-Requested-With': 'XMLHttpRequest',
    };

    const config = {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      xsrfHeaderName: 'APPZEN_CSRFTOKEN',
      xsrfCookieName: 'JSESSIONID',
      headers: headers,
      body: qs.stringify(requestParams),
    };

    return fetch(`${api.expenseBaseUrl}j_spring_security_check`, config)
      .then(response => {
        return response.json();
      })
      .catch(error => {
        console.log('getTokenFetchAPI-error:', error);
        return error;
      });
  },
  ssoLogin(domain = '') {
    const requestUrl = `rest/expenses/getendpointurl?domain=${domain}`;

    return authInstance
      .get(requestUrl);
  },
  logoutUser() {
    const requestUrl = `${api.expenseBaseUrl}j_spring_security_logout`;
    return fetcher(requestUrl, { method: 'GET' }, true, false)
      .then(res => res)
      .catch(error => error);
  },
  refreshAuthToken() {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'okta-refresh-token': authStorage.getItem('APPZEN_OKTATOKEN') || null,
        'APPZEN-CSRFTOKEN': authStorage.getItem('APPZEN_CSRFTOKEN'),
        'APPZEN_CSRFTOKEN': authStorage.getItem('APPZEN_CSRFTOKEN'),
        'OWASP_CSRFTOKEN': authStorage.getItem('OWASP_CSRFTOKEN'),
      },
    };

    let url;
    if (authStorage.getItem('APPZEN_CSRFTOKEN')) {
      url = `${api.authServiceBaseUrl}/api/v3/token/refresh`;
    } else {
      url = `${api.authServiceBaseUrl}/api/v2/token/refresh`
    }

    return fetcher(url, config, false, false)
      .then(response => response.data)
      .catch(error => error);
  },
};

export default apiUserLogin;
