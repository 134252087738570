import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@az/components';
import './SupportCardFormField.scss';

function SupportCardFormField(props) {
  const {
    label,
    value,
    options,
    isDisabled,
    handleChange,
    handleQueryForInput,
  } = props;

  return (
    <div className="support-card-form__field">
      <Autocomplete
        label={label}
        closeDropdownOnSelect
        placeholder=""
        options={options}
        customClass="az-autocomplete--line-border"
        selected={value}
        handleChange={handleChange}
        isSingleSelect
        isDisabled={isDisabled}
        handleQueryPromise={handleQueryForInput}
      />
    </div>
  );
}

SupportCardFormField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
  })),
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
  })),
  isDisabled: PropTypes.bool,
  handleChange: PropTypes.func,
  handleQueryForInput: PropTypes.func,
};

SupportCardFormField.defaultProps = {
  label: '',
  value: [],
  options: [],
  isDisabled: true,
  handleChange: null,
  handleQueryForInput: null,
};

export default SupportCardFormField;
