import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { createBrowserHistory as createHistory } from 'history';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { globalConfig } from '@az/common-configs';
import { getAppRouterBasePath } from '@az/utility';
import { Notifications, notificationsActions } from '@az/components';

import './App.css';
import Login from '../featureComponents/Login/Login';
import Layout from '../Layout/Layout';
import { basePath, defaultRoute } from '../config/appConfig';

const tagManagerArgs = {
  gtmId: globalConfig.googleTagManagerId,
};

TagManager.initialize(tagManagerArgs);

const history = createHistory();
ReactGA.initialize(globalConfig.googleAnalyticsId);

history.listen((location) => {
  ReactGA.pageview(location.pathname + location.search);
});

function App() {
  const reactAppRouterBase = getAppRouterBasePath(basePath);

  // Overwrite Material-UI default Roboto font with Open Sans.
  const theme = createMuiTheme({
    typography: {
      fontFamily: ['"Open Sans"', 'sans-serif'].join(','),
      useNextVariants: true,
    },
  });

  const notifications = useSelector(({ notificationsReducer }) => notificationsReducer.alertNotifications);
  const dispatch = useDispatch();

  const handleHideNotification = (id) => {
    dispatch(notificationsActions.hideNotification(id));
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Switch>
        <Route exact path={`${reactAppRouterBase}/login`} component={Login} />
        <Route path={`${reactAppRouterBase}/`} component={Layout} />
        <Redirect from={`${reactAppRouterBase}`} to={`${reactAppRouterBase}/${defaultRoute}`} />
      </Switch>
      <Notifications notifications={notifications} onHideNotification={handleHideNotification} />
    </MuiThemeProvider>
  );
}

export default App;
