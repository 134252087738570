import React, { Component } from 'react';
import MarketingWrapper from './MarketingWrapper/MarketingWrapper';
import LoginWrapper from './LoginWrapper/LoginWrapper';

import LoginCartoon from '../../assets/images/login_01_1x.png';
import './Login.scss';

class Login extends Component {
  render () {
    return (
      <div className="login">
        <div className="login__wrapper-col login__wrapper-col--colored u-hidden-tab-port">
          <MarketingWrapper loginCartoon={LoginCartoon} />
        </div>

        <div className="login__wrapper-col">
          <LoginWrapper />
        </div>
      </div>
    )
  }
}

export default Login;
