/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import {
  localizationConfig,
  uiConfig,
  sideNavComputations,
} from '@az/common-configs';
import { LoadingIndicator, showAlert } from '@az/components';
import {
  isArrayWithData,
  isDefined,
  isObject,
  getAppRouterBasePath,
  authStorage,
} from '@az/utility';
import { SecuredRoute } from '@az/core-components';
import { AppSwitcher } from '@az/app-switcher';
import {
  Footer,
  SessionCountdownDialog,
} from './index';
import { basePath } from '../config/appConfig';
import * as actionCreators from '../store/actions/action-coreService';
import EmulateUser from '../azCoreFeatureComponents/Support/EmulateUser/EmulateUser';
import EmulateSuccess from '../azCoreFeatureComponents/Support/EmulateUser/EmulateSuccess/EmulateSuccess';
import SupportDashboard from '../azCoreFeatureComponents/Support/SupportDashboard/SupportDashboard';
import AAAReportGeneration from '../featureComponents/AAAReportGeneration/ReportGeneration/ReportGeneration';
import { coreService } from '../store/service/api-coreService';
import apiUserLogin from '../featureComponents/Login/LoginWrapper/api-login';
import { userService } from '../featureComponents/User/api-user-service';

const {
  COLOR,
  SITE_FOOTER_HEIGHT,
  DEFAULT_SESSION_COUNTDOWN_BLINK_TIME_IN_MS,
} = uiConfig;
const { global } = localizationConfig;
const { PRODUCT, formatUserDetailsObject } = sideNavComputations;

const GlobalStyle = createGlobalStyle`
  #password-validation-toolbox {
    top: 90px;
  }
`;

const AzHome = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto !important;
  position: relative;
`;

const AzHomeHeader = styled.div`
  width: 100%;
  height: 55px;
  background-image: none;
  background-color: #25282F;
`;

const AzHomeBody = styled.div`
  clear: both;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: calc(100vh - 55px);
  overflow: hidden;

  @media print {
    overflow: visible;
  }
`;

const AzHomeBodyContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;
  margin-left: 0;
  padding-bottom: 0;
  flex-grow: 1;
  height: calc(100vh - 55px);
  min-height: auto;

  @media print {
    overflow: visible;
  }
`;

const AzHomeBodyContent = styled.div`
  display: flex;
  background: ${COLOR.DEFAULTS.BODY_BACKGROUND};
  flex-grow: 1;
`;

const AzHomeFooter = styled.div`
  display: block;
  position: static;
  height: ${SITE_FOOTER_HEIGHT};
  bottom: 0;
  width: 100%;
`;

function Layout() {
  const isLoading = useSelector((state) => state.ui.isLoading);
  const {
    userDetails,
    customerConfigValues,
    isSessionAboutToExpire,
    applicationConfig,
  } = useSelector((state) => state.core);

  const [isSessionCountdownDialogOpen, setIsSessionTimeoutDialogOpen] = useState(false);

  let blinkTitleInterval;

  const dispatch = useDispatch();
  const reactAppRouterBase = getAppRouterBasePath(basePath);
  const renderRoutes = () => (
    <Switch>
      <SecuredRoute path={`${reactAppRouterBase}/support/emulate-user`} component={EmulateUser} />
      <SecuredRoute path={`${reactAppRouterBase}/support/emulate-success`} component={EmulateSuccess} />
      <SecuredRoute path={`${reactAppRouterBase}/aaa-reports/report-generation`} component={AAAReportGeneration} />
      <SecuredRoute path={`${reactAppRouterBase}/support/dashboard`} component={SupportDashboard} />
    </Switch>
  );

  const handleExtendSession = () => {
    setIsSessionTimeoutDialogOpen(false);
    dispatch(actionCreators.setSessionIsAboutToExpire(false));
    dispatch(actionCreators.requestResetSession());
  };

  const handleLogout = () => {
    setIsSessionTimeoutDialogOpen(false);
    dispatch(actionCreators.logoutUser());
  };

  useEffect(() => {
    if (!isObject(userDetails, true) || !isArrayWithData(customerConfigValues)) {
      dispatch(actionCreators.initialLoad());
    }
    dispatch(actionCreators.setSessionIsAboutToExpire(false));
  }, []);

  useEffect(() => {
    if (isSessionAboutToExpire) {
      setIsSessionTimeoutDialogOpen(true);
    }
  }, [isSessionAboutToExpire]);

  useEffect(() => {
    if (isObject(userDetails, true)) {
      authStorage.setItem('USER_PRODUCT', PRODUCT.AUTONOMOUS_AP);
    }
  }, [userDetails]);

  useEffect(() => {
    const originalDocumentTitle = document.title;
    if (isSessionCountdownDialogOpen) {
      if (!blinkTitleInterval) {
        blinkTitleInterval = setInterval(() => {
          document.title = document.title === originalDocumentTitle ? '🕔' : originalDocumentTitle;
        }, DEFAULT_SESSION_COUNTDOWN_BLINK_TIME_IN_MS);
      }
    } else if (blinkTitleInterval) {
      clearInterval(blinkTitleInterval);
    }
    return () => {
      clearInterval(blinkTitleInterval);
      document.title = originalDocumentTitle;
    };
  }, [isSessionCountdownDialogOpen]);

  function clearStorage() {
    authStorage.removeItem('OWASP_CSRFTOKEN');
    authStorage.removeItem('APPZEN_JWTTOKEN');
    authStorage.removeItem('APPZEN_CSRFTOKEN');
    authStorage.removeItem('APPZEN_OKTATOKEN');
    authStorage.removeItem('APPZEN_JWTTOKEN_FIAT');
    authStorage.removeItem('APPZEN_JWTTOKEN_IAT');
    authStorage.removeItem('APPZEN_JWTTOKEN_EXP');
    authStorage.removeItem('session');

    const sessionExpired = sessionStorage.getItem('SessionExpired') === 'true';
    sessionStorage.clear();
    if (sessionExpired) {
      sessionStorage.setItem('SessionExpired', 'true');
    }
  }

  const switchToAdminAPI = () => coreService.switchToAdminUser().then((response) => {
    if (response.status === 200) {
      authStorage.removeItem('USER_PRODUCT');
      if (!authStorage.getItem('APPZEN_CSRFTOKEN')) {
        authStorage.setItem('APPZEN_JWTTOKEN', response.data.APPZEN_JWTTOKEN.token || '');
        authStorage.setItem('APPZEN_JWTTOKEN_FIAT', response.data.APPZEN_JWTTOKEN.fiat || '');
        authStorage.setItem('APPZEN_JWTTOKEN_IAT', response.data.APPZEN_JWTTOKEN.iat || '');
        authStorage.setItem('APPZEN_JWTTOKEN_EXP', response.data.APPZEN_JWTTOKEN.exp || '');
      }
      authStorage.removeItem('session');
      dispatch(actionCreators.initialLoad());
      dispatch(actionCreators.resetApp());
      window.location.href = '/console/index.html#/Support/SupportDashboard';
    } else {
      dispatch(actionCreators.getNotificationAction('error', 'Error', response.data && response.data.msg ? response.data.msg : global.notificationMessages.SERVER_REQUEST_FAILED, true, 5000));
    }
  });
  const switchToUserAPI = (userId, customerId) => (
    coreService.switchAccount(
      userId,
      customerId,
    )
  );

  const logoutAPI = () => apiUserLogin.logoutUser().then((response) => {
    dispatch(actionCreators.setSessionIsAboutToExpire(false));
    clearStorage();
    dispatch(showAlert(response.msg || global.notificationMessages.LOGOUT_COMPLETE, 'success'));
    dispatch(actionCreators.resetApp());

    if (process.env.NODE_ENV === 'production') {
      const logoutRedirectUrl = `${window.location.origin}/console/home.html`; // redirect to angular login page

      window.location.href = (response.data && isDefined(response.data.samlLogoutUrl))
        ? response.data.samlLogoutUrl : logoutRedirectUrl;
    }
  });
  const notificationsAPI = () => coreService.getUsersNotifications().then((res) => {
    if (res?.data?.statusCodeValue === 200) {
      return res.data.body;
    }
    return { notifications: [], notificationsTotalCount: 0 };
  }).catch(() => ({ notifications: [], notificationsTotalCount: 0 }));

  const changePasswordAPI = (currentPassword, newPassword) => (
    userService.changePassword(currentPassword, newPassword)
  );

  const zendeskTokenAPI = () => coreService.getZendeskToken().then((res) => res?.data);

  return (
    <AzHome>
      <GlobalStyle />
      <AzHomeHeader>
        {
          (isObject(userDetails, true))
            && (
              <AppSwitcher
                userDetailsAPI={() => Promise.resolve(formatUserDetailsObject(userDetails))}
                switchToAdminAPI={switchToAdminAPI}
                switchToUserAPI={switchToUserAPI}
                logOutAPI={logoutAPI}
                appConfigAPI={() => Promise.resolve(applicationConfig)}
                zendeskTokenAPI={zendeskTokenAPI}
                customerConfigAPI={() => Promise.resolve(customerConfigValues)}
                navigateToUrl={(url) => { window.location.href = url; }}
                isVisible
                productsAPI={() => coreService.getProductsList(userDetails.customer_id)
                  .then((res) => res.data)}
                notificationsAPI={notificationsAPI}
                changePasswordAPI={changePasswordAPI}
                forceHideOrganizationsDropdown
              />
            )
        }
      </AzHomeHeader>
      <AzHomeBody id="app-menu">
        <AzHomeBodyContainer>
          <AzHomeBodyContent>
            {renderRoutes()}
          </AzHomeBodyContent>
          <AzHomeFooter>
            <Footer />
          </AzHomeFooter>
        </AzHomeBodyContainer>
      </AzHomeBody>
      <SessionCountdownDialog
        isOpen={isSessionCountdownDialogOpen}
        onLogout={handleLogout}
        onExtendSession={handleExtendSession}
      />
      {isLoading && <LoadingIndicator message={global.loadingMessages.GENERIC_LOADING} />}
    </AzHome>
  );
}

export default Layout;
