import * as actions from '../actions/actionTypes';
import { updateObject } from '@az/utility';

const initialState = {
  loadConfigValuesInProgress: false,
  loadConfigValuesComplete: false,
  userDetails: {},
  customerConfigValues: [],
  applicationConfigValues: {},
  customerName: null,
  customerCurrencyCodes: [],
  customerOrganizations: [],
  isAppZenSuperAdminLogin: false,
  isConcurPartnerAdmin: false,
  isUserEmulated: false,
  initialLoadError: null,
  isSessionAboutToExpire: false,
  isRequestSessionInProgress: false,
  customerDefaultSessionTimeInSec: null,
};

const initialLoadStart = (state, action) => updateObject(state, {
  loadConfigValuesInProgress: true,
  userDetails: {},
  customerConfigValues: [],
  applicationConfigValues: {},
  customerName: null,
  customerCurrencyCodes: [],
  customerOrganizations: [],
  isAppZenSuperAdminLogin: false,
  isConcurPartnerAdmin: false,
  isUserEmulated: false,
  isEmulatedByEmulator: false,
  initialLoadError: null,
});

const initialLoadFail = (state, action) => updateObject(state, {
  loadConfigValuesInProgress: false,
  loadConfigValuesComplete: true,
  userDetails: {},
  customerConfigValues: [],
  applicationConfigValues: {},
  customerName: null,
  customerCurrencyCodes: [],
  customerOrganizations: [],
  isAppZenSuperAdminLogin: false,
  isConcurPartnerAdmin: false,
  isUserEmulated: false,
  isEmulatedByEmulator: false,
  initialLoadError: action.data,
});

const initialLoadSuccess = (state, action) => updateObject(state, {
  loadConfigValuesInProgress: false,
  loadConfigValuesComplete: true,
  userDetails: action.data.userDetails.data,
  customerConfigValues: action.data.customerConfigValues.data,
  applicationConfigValues: action.data.applicationConfigValues.data,
  customerName: action.data.userDetails.data.customer_name,
  customerCurrencyCodes: action.data.currencyCodes,
  customerOrganizations: action.data.organizations,
  isAppZenSuperAdminLogin: action.data.isAppZenSuperAdminLogin,
  isConcurPartnerAdmin: action.data.isConcurPartnerAdmin,
  isEmulatedByEmulator: action.data.isEmulatedByEmulator,
  isUserEmulated: action.data.isUserEmulated,
  initialLoadError: null,
  isTiEnabledForCustomer: action.data.isTiEnabledForCustomer,
  isIntegrationsMenuEnabled: action.data.isIntegrationsMenuEnabled,
  isFetchMastermindRuleEnabled: action.data.isFetchMastermindRuleEnabled,
  applicationConfig: action.data.applicationConfig,
});

const setCustomerDefaultSessionTimeInSec = (state, action) => updateObject(state, {
  customerDefaultSessionTimeInSec: action.defaultSessionTimeInSec,
});

const setRequestSessionStarted = (state) => updateObject(state, {
  isRequestSessionInProgress: true,
});

const setRequestSessionCompleted = (state) => updateObject(state, {
  isRequestSessionInProgress: false,
});

const setSessionIsAboutToExpire = (state, action) => updateObject(state, {
  isSessionAboutToExpire: action.isSessionAboutToExpire,
});

const coreServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INITIAL_LOAD_START:
      return initialLoadStart(state, action);
    case actions.INITIAL_LOAD_SUCCESS:
      return initialLoadSuccess(state, action);
    case actions.INITIAL_LOAD_FAIL:
      return initialLoadFail(state, action);
    case actions.SET_CUSTOMER_DEFAULT_SESSION_TIME_IN_SEC:
      return setCustomerDefaultSessionTimeInSec(state, action);
    case actions.SET_SESSION_IS_ABOUT_TO_EXPIRE:
      return setSessionIsAboutToExpire(state, action);
    case actions.REQUEST_RESET_SESSION_START:
      return setRequestSessionStarted(state);
    case actions.REQUEST_RESET_SESSION_COMPLETE:
      return setRequestSessionCompleted(state);
    default:
      return state;
  }
};

export default coreServiceReducer;
