import * as actionTypes from './actionTypes';

export function initialLoadStart() {
  return {
    type: actionTypes.INITIAL_LOAD_START,
  };
}

export function initialLoadSuccess(data) {
  return {
    type: actionTypes.INITIAL_LOAD_SUCCESS,
    data,
  };
}

export function initialLoadFail(error) {
  return {
    type: actionTypes.INITIAL_LOAD_FAIL,
    data: error,
  };
}

export const initialLoad = () => ({
  type: actionTypes.INITIAL_LOAD,
});

export const resetApp = () => ({
  type: actionTypes.RESET_APP,
});

export const logoutUser = () => ({
  type: actionTypes.LOGOUT_USER,
});

export const startSessionTimer = (from) => ({
  type: actionTypes.START_SESSION_TIMER,
  from,
});

export const requestResetSessionStarted = () => ({
  type: actionTypes.REQUEST_RESET_SESSION_START,
});

export const requestResetSession = () => ({
  type: actionTypes.REQUEST_RESET_SESSION,
});

export const requestResetSessionCompleted = () => ({
  type: actionTypes.REQUEST_RESET_SESSION_COMPLETE,
});

export const setCustomerDefaultSessionTimeInSec = (timeInSec) => ({
  type: actionTypes.SET_CUSTOMER_DEFAULT_SESSION_TIME_IN_SEC,
  defaultSessionTimeInSec: timeInSec,
});

export const setSessionIsAboutToExpire = (isSessionAboutToExpire) => ({
  type: actionTypes.SET_SESSION_IS_ABOUT_TO_EXPIRE,
  isSessionAboutToExpire,
});

export const switchUser = () => ({
  type: actionTypes.SWITCH_USER,
});

export const getNotificationAction = (
  type,
  title,
  message,
  autoClose = false,
  autoCloseDelay = 3000,
) => ({
  type: actionTypes.SHOW_NOTIFICATION,
  payload: {
    data: {
      type,
      title,
      message,
      autoClose,
      autoCloseDelay,
    },
  },
});

export const showLoading = () => ({
  type: actionTypes.SHOW_LOADING,
});

export const hideLoading = () => ({
  type: actionTypes.HIDE_LOADING,
});
