import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import './Footer.scss';
import displayLocalDateObj from '../../config/utility';

const Footer = () => {
  const { formatMessage } = useIntl();
  const { userDetails } = useSelector(({ core }) => core);
  let localDate = null;

  if (userDetails && userDetails.last_login_time) {
    localDate = displayLocalDateObj(userDetails.last_login_time);
  }

  return (
    <footer className="footer">
      <div className="footer__col">
        <span className="footer__text">
          {formatMessage({ id: 'global.footerTitles.COPYRIGHT_MESSAGE_PART_1' })}
          {' '}
          &copy;
          {' '}
          {formatMessage({ id: 'global.footerTitles.COPYRIGHT_MESSAGE_PART_2' })}
        </span>
      </div>
      {localDate && (
        <div className="footer__col">
          <span className="footer__text">
            {formatMessage(
              { id: 'global.footerTitles.LAST_LOGIN' },
              { date: localDate.inlDate, time: localDate.timestamp },
            )}
          </span>
        </div>
      )}
      <div className="footer__col">
        <span className="footer__text">
          {formatMessage({ id: 'global.footerTitles.BUILD_TITLE' })}
          {' '}
          {process.env.REACT_APP_BUILD_NUMBER}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
