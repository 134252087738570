import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isDefined, processInputChange } from '@az/utility';
import { Button, Checkbox, Input } from '@az/components';
import SessionExpiredDialog from '../SessionExpiredDialog/SessionExpiredDialog';


class SignInForm extends Component {
  state = {
    loginForm: {
      username: {
        value: undefined,
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      password: {
        value: undefined,
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      rememberMe: {
        value: false, // this is the checkbox's isChecked value, not the input value
        validation: {
          required: false,
        },
        valid: false,
        touched: false,
      },
    },
    formIsValid: false,
    isSessionExpiredDialogOpen: false,
  };

  static propTypes = {
    username: PropTypes.string,
    useDirectSSO: PropTypes.bool,
    onSubmitSignInForm: PropTypes.func.isRequired,
    onSignInWithSSO: PropTypes.func,
    showPasswordField: PropTypes.bool,
    showSSOLink: PropTypes.bool,
  };

  componentDidMount() {
    if (sessionStorage.getItem('SessionExpired') === 'true') {
      document.title = this.props.intl.formatMessage({ id: 'global.sessionExpiredDialog.TAB_TITLE' });
      this.setState({
        isSessionExpiredDialogOpen: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const loginForm = { ...this.state.loginForm };
    if (isDefined(this.props.username) && this.props.username !== prevProps.username) {
      loginForm.username.value = this.props.username;
      this.setState({ loginForm });
    }
  }

  handleInputChange = (e, identifier) => {
    const form = { ...this.state.loginForm };
    const { value } = e.target;

    const processedResult = processInputChange(form, value, identifier);

    this.setState({
      loginForm: processedResult.updatedForm,
      formIsValid: processedResult.formIsValid,
    });
  }

  handleFieldChange = (e) => {
    this.handleInputChange(e, e.target.name);
  };

  handleRememberMeToggle = (e) => {
    const form = { ...this.state.loginForm };
    form.rememberMe.value = e.target.checked;
    this.setState({
      loginForm: form,
    });
  };

  handleSSO = (e, username = this.state.loginForm.username.value) => {
    e.preventDefault();
    this.props.onSignInWithSSO(e, username);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.useDirectSSO) {
      this.handleSSO(e, 'amazonsso');
    } else {
      this.props.onSubmitSignInForm(e, this.state.loginForm);
    }
  };

  handleCloseSessionExpiredDialog = (e) => {
    sessionStorage.removeItem('SessionExpired');
    this.setState({
      isSessionExpiredDialogOpen: false,
    });
  };

  renderUsername = () => {
    const usernameFieldObj = this.state.loginForm.username;

    return (
      <Input
        elementAttributes={{
          name: 'username',
          type: 'text',
          id: 'username',
          autoFocus: !isDefined(usernameFieldObj.value),
          value: usernameFieldObj.value || '',
        }}
        label="Email address"
        onChange={this.handleFieldChange}
        theme="bottom-only mail"
        isValid={usernameFieldObj.valid}
        isTouched={usernameFieldObj.touched}
        isRequired={usernameFieldObj.validation.required}
      />
    );
  }

  renderPassword = () => {
    const passwordFieldObj = this.state.loginForm.password;

    return this.props.showPasswordField ? (
      <>
        <Input
          elementAttributes={{
            name: 'password',
            type: 'password',
            id: 'password',
            autoFocus: isDefined(this.state.loginForm.username.value),
            value: passwordFieldObj.value || '',
            autoComplete: 'off',
          }}
          onChange={this.handleFieldChange}
          theme="bottom-only lock"
          isValid={passwordFieldObj.valid}
          isTouched={passwordFieldObj.touched}
          label="Password"
          isRequired={passwordFieldObj.validation.required}
        />
        <div className="form__insert u-h-space-between">
          <Checkbox label="Stay signed in" onSelect={this.handleRememberMeToggle} isChecked={this.state.loginForm.rememberMe.value} name="rememberMe" />
          <Link to="login/request-password-reset" className="form__link">Forgot password?</Link>
        </div>
      </>
    ) : null;
  };

  renderSSOLink = () => (this.props.showSSOLink ? (
    <div onClick={this.handleSSO} className="loginWrapper__sso-link">
      <span className="form__link"> Single sign on with SSO </span>
    </div>
  ) : null);

  renderButton = () => {
    const {
      showSSOLink, useDirectSSO, showPasswordField,
      intl: { formatMessage },
    } = this.props;
    let buttonText = '';
    if (useDirectSSO) {
      buttonText = formatMessage({ id: 'global.buttonText.SINGLE_SIGN_ON_WITH_SSO' });
    } else if (!showSSOLink) {
      buttonText = formatMessage({ id: 'global.buttonText.SIGN_IN' });
    } else {
      buttonText = showPasswordField ? formatMessage({ id: 'global.buttonText.SIGN_IN' }) : formatMessage({ id: 'global.buttonText.NEXT' });
    }

    return (
      <Button
        classes="loginWrapper__button button--brand button--round"
        elementAttributes={{ type: 'submit' }}
      >
        {buttonText}
      </Button>
    );
  };

  render() {
    return (
      <>
        <form className="form form--single loginWrapper__form" noValidate onSubmit={this.handleSubmit}>
          {this.props.useDirectSSO ? null : (
            <div className="form__body">
              <div className="loginWrapper__form-field">
                {this.renderUsername()}
              </div>

              <div className="loginWrapper__form-field">
                {this.renderPassword()}
              </div>
            </div>
          )}
          <div className="form__footer">
            {this.renderButton()}
            {this.renderSSOLink()}
          </div>
        </form>
        <SessionExpiredDialog isOpen={this.state.isSessionExpiredDialogOpen} onClose={this.handleCloseSessionExpiredDialog} />
      </>
    );
  }
}

SignInForm.propTypes = {
  username: PropTypes.string,
  useDirectSSO: PropTypes.bool,
  onSubmitSignInForm: PropTypes.func.isRequired,
  onSignInWithSSO: PropTypes.func,
  showPasswordField: PropTypes.bool,
  showSSOLink: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

export default injectIntl(SignInForm);
