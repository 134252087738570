import { localizationConfig } from '@az/common-configs';
import {
  generateQueryURLWithParams,
  createDropdownDataObj,
  isArrayWithData,
  isObject,
} from '@az/utility';
import { getNotificationAction } from '../../../store/actions/action-coreService';
import { fetcher } from '../../../store/service/helper';
import api from '../../../store/service/api';

const { support, global } = localizationConfig;

const dispatchNetworkError = (errorMsg, dispatch) => {
  const errorAction = getNotificationAction('error', global.error, errorMsg, true, 3000);
  dispatch(errorAction);
};

const apiEmulateUser = {
  getCustomers(customerConfig, dispatch) {
    const {
      page, start, limit, search, sort,
    } = customerConfig;

    const paramConfig = {
      page: page || 1,
      start: start || 0,
      limit: limit || 25,
      search: {
        value: search || '',
      },
      sort: sort || {
        property: null,
        direction: 'ASC',
      },
    };

    const config = {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const requestURL = generateQueryURLWithParams(
      `${api.expenseBaseUrl}rest/appZenSupportService/getCustomers`,
      paramConfig,
    );
    return fetcher(requestURL, config, false)
      .then((response) => {
        if (isObject(response, true) && response.status === 200) {
          if (isArrayWithData(response.data)) {
            const customers = createDropdownDataObj(response.data, 'customer_id', 'customer_name');
            return customers;
          }
          return [];
        }

        const errorMsg = response.data && response.data.msg
          ? response.data.msg
          : support.emulateUser.api.GET_CUSTOMERS_ERROR;
        dispatchNetworkError(errorMsg, dispatch);
        return [];
      })
      .catch(() => {
        dispatchNetworkError(support.emulateUser.api.GET_CUSTOMERS_ERROR, dispatch);
        return [];
      });
  },

  getUsers(userConfig, dispatch) {
    const {
      page, start, limit, search, customer, sort,
    } = userConfig;

    const paramConfig = {
      page: page || 1,
      start: start || 0,
      limit: limit || 25,
      search: {
        value: search || '',
        custId: customer.id || '',
      },
      sort: sort || {
        property: null,
        direction: 'ASC',
      },
    };

    const config = {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const requestURL = generateQueryURLWithParams(
      `${api.expenseBaseUrl}rest/appZenSupportService/getAllUsers`,
      paramConfig,
    );
    return fetcher(requestURL, config, false)
      .then((response) => {
        if (isObject(response, true) && response.status === 200) {
          if (isArrayWithData(response.data)) {
            const usersForCustomer = response.data.map((user) => ({
              id: user.az_user_id,
              text: `${user.first_name ? user.first_name : ''} ${user.last_name ? user.last_name : ''}`,
            }));
            return usersForCustomer;
          }
          return [];
        }

        const errorMsg = response.data && response.data.msg
          ? response.data.msg
          : support.emulateUser.api.GET_USERS_ERROR;
        dispatchNetworkError(errorMsg, dispatch);
        return [];
      })
      .catch(() => {
        dispatchNetworkError(support.emulateUser.api.GET_USERS_ERROR, dispatch);
        return [];
      });
  },

  emulateUser(userId, customerId) {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return fetcher(
      `${api.expenseBaseUrl}rest/appZenSupportService/switchuser?userId=${userId}&customerId=${customerId}`,
      config,
      true,
    )
      .then((response) => response)
      .catch((error) => error);
  },
};

export default apiEmulateUser;
