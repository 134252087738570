import { updateObject } from '@az/utility';
import * as actions from '../../../store/actions/actionTypes';

const initialState = {
  userLoginInProgress: false,
  userAuthenticated: false,
  userLogoutInProgress: false,
  userLogoutComplete: false,
  userInfo: {},
  error: null
};

const loginStart = (state, action) => {
  return updateObject(state, {
    userLoginInProgress: true,
    userAuthenticated: false,
    userLogoutInProgress: false,
    userLogoutComplete: false,
    userInfo: {},
    error: null
  });
};

const loginFail = (state, action) => {
  return updateObject(state, {
    userLoginInProgress: false,
    userAuthenticated: false,
    error: action.error
  });
};

const loginSuccess = (state, action) => {
  const {data} = action.payload;

  return updateObject(state, {
    userLoginInProgress: false,
    userAuthenticated: true,
    error: null,
    userInfo: data.user_details
  });
};

const logoutUserStart = (state, action) => {
  return updateObject(state, {
    userLogoutInProgress: true,
    userLogoutComplete: false
  });
};

const logoutUserSuccess = (state, action) => {
  return updateObject(state, {
    userLogoutInProgress: false,
    userLogoutComplete: true,
    userAuthenticated: false,
    userInfo: {}
  });
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN_START:
      return loginStart(state, action);
    case actions.LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case actions.LOGIN_FAIL:
      return loginFail(state, action);
    case actions.LOGOUT_IN_PROGRESS:
      return logoutUserStart(state, action);
    case actions.LOGOUT_COMPLETE:
      return logoutUserSuccess(state, action);
    default:
      return state;
  }
};

export default authReducer;
