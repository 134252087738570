import { takeLatest, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { localizationConfig } from '@az/common-configs';
import { getAppRouterBasePath, isObject, authStorage } from '@az/utility';
import * as actions from '../../../store/actions/actionTypes';
import { getNotificationAction } from '../../../store/actions/action-coreService';
import { basePath } from '../../../config/appConfig';
import apiEmulateUser from './api-emulateUser';

const { support, global } = localizationConfig;

function* emulateUser(action) {
  const {
    user: { id: userId, text: userName },
    customer: { id: customerId, text: customerName },
  } = action.payload;

  const response = yield call(apiEmulateUser.emulateUser, userId, customerId);
  const reactAppRouterBase = getAppRouterBasePath(basePath);

  if (isObject(response, true) && response.status === 200) {
    if (!authStorage.getItem('APPZEN_CSRFTOKEN')) {
      authStorage.setItem('APPZEN_JWTTOKEN', response.data.APPZEN_JWTTOKEN.token || '');
      authStorage.setItem('APPZEN_JWTTOKEN_FIAT', response.data.APPZEN_JWTTOKEN.fiat || '');
      authStorage.setItem('APPZEN_JWTTOKEN_IAT', response.data.APPZEN_JWTTOKEN.iat || '');
      authStorage.setItem('APPZEN_JWTTOKEN_EXP', response.data.APPZEN_JWTTOKEN.exp || '');
    }

    authStorage.removeItem('session');

    yield put({ type: actions.INITIAL_LOAD });
    yield put({ type: actions.RESET_APP });
    yield put(push({ pathname: `${reactAppRouterBase}/support/emulate-success`, state: { userName, customerName } }));
  } else {
    const errorMsg = (response.data && response.data.msg)
      ? response.data.msg
      : support.emulateUser.api.EMULATE_USER_ERROR;
    const errorAction = getNotificationAction('error', global.error, errorMsg, true, 3000);
    yield put(errorAction);
  }
}
const emulateUserSaga = [
  takeLatest(actions.EMULATE_USER, emulateUser),
];
export default emulateUserSaga;
