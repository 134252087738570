import React from 'react';
import './MarketingWrapper.scss';

const MarketingWrapper = (props) => {
  return (
    <div className="marketing">
      <div className="marketing__content">
        <img src={props.loginCartoon} alt="AppZen" className="marketing__hero-img" />
      </div>
    </div>
  );
};

export default MarketingWrapper;
