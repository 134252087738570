// eslint-disable-next-line import/prefer-default-export
export const AP = {
  apAdmin: {
    api: {
      GET_AP_MAPPING_ERROR: 'Error retrieving mapping',
      GET_SYSTEM_LOOKUP_ERROR: 'Error retrieving system lookup',
      UPDATE_AP_MAPPING_ERROR: 'Error mapping',
      UPDATE_AP_MAPPING_SUCCESS: 'Successfully mapped',
    },
    text: {
      ADD_PAYMENT_TERM_DISCOUNT: 'add payment term discount',
      APPZEN_CONTRACT_TYPE_NAME: 'AppZen Contract Type Name',
      APPZEN_WORKFLOW_STATUS: 'AppZen Workflow Status',
      APPZEN_PAYMENT_TERM: 'AppZen Payment Term',
      CANCEL: 'Cancel',
      CONTRACT_TYPE_NAME: 'Contract Type Name',
      CONTRACT_TYPE_MAPPING: 'Contract Type Mapping',
      DAYS: 'days',
      NONE_FOUND: 'None found',
      NUMBER_DAYS: '# Days',
      PAYMENT_TERM: 'Payment Term',
      PAYMENT_TERM_CODE: 'Payment Term Code',
      PAYMENT_TERM_DISCOUNT: 'Payment Term Discount',
      PAYMENT_TERM_MAPPING: 'Payment Term Mapping',
      MISSING_REQUIRED_FIELDS: 'Please fill out all required fields',
      SAVE: 'Save',
      WORKFLOW_MAPPING: 'Workflow Mapping',
      WORKFLOW_STATUS: 'Workflow Status',
    },
    metadata: {
      ALL_TERMS: 'All Term(s)',
      ALL_TYPES: 'All Type(s)',
      ALL_WORKFLOWS: 'All Workflow(s)',
      MAPPED_TERMS: 'Mapped Term(s)',
      MAPPED_TYPES: 'Mapped Type(s)',
      MAPPED_WORKFLOWS: 'Mapped Workflow(s)',
      UNMAPPED_TERMS: 'Unmapped Term(s)',
      UNMAPPED_TYPES: 'Unmapped Type(s)',
      UNMAPPED_WORKFLOWS: 'Unmapped Workflow(s)',
    },
    organizations: {
      text: {
        TITLE: 'AP Organizations',
        SUBTITLE: 'Admins can enable and disable organizations, set customer default organization, and assign organization access to users.',
        ORGANIZATIONS: 'Organizations',
        STATUS: 'Status',
        SELECT_BULK_ACTION: 'Select a Bulk Action',
        BULK_ENABLE: 'Bulk Disable Organizations',
        BULK_DISABLE: 'Bulk Enable Organizations',
      },
      api: {
        API_GET_ORGANIZATIONS_ERROR: 'Error retrieving organizations.',
        API_PUT_ORGANIZATIONS_ERROR: 'Error updating organization.',
        API_PUT_ORGANIZATIONS_SUCCESS: 'Successfully updated organization(s).',
      },
    },
    auditConfiguration: {
      text: {
        INVOICE_TITLE: 'AP Audit Configuration',
        CONTRACT_TITLE: 'Contract Audit Configuration',
        SUBTITLE: 'The following models are configurable',
        RISK_LEVEL_LOW: 'Low',
        RISK_LEVEL_MED: 'Medium',
        RISK_LEVEL_HIGH: 'High',
        HEADER_RULES_TITLE: 'Header Level Audit Models',
        LINE_RULES_TITLE: 'Line Level Audit Models',
        SUCCESS: 'Success',
        ERROR: 'Error',
        AUDIT_MODEL_NAME: 'Audit Model Name',
        LAST_UPDATED: 'Last Updated',
        STATUS: 'Status',
        ON: 'On',
        OFF: 'Off',
        EXPORT_CONFIGURATION: 'Export Configuration',
        SAVE: 'Save',
        CANCEL: 'Cancel',
        HIGH_RISK: 'High Risk',
        MED_RISK: 'Medium risk',
      },
      api: {
        API_UPDATE_RULE_SUCCESS: 'Audit model has been updated.',
        API_UPDATE_RULE_ERROR: 'Error updating audit model.',
        API_GET_RULE_ERROR: 'Error retrieving audit model.',
        API_GET_RULE_ERRORS: 'Error retrieving audit models.',
        API_EXPORT_CONFIG_SUCCESS: 'Downloading audit configuration.',
        API_EXPORT_CONFIG_ERROR: 'Error exporting audit configurations.',
        EMPTY_FIELDS_ERROR: 'Please fill all required fields.',
      },
    },
    deleteFiles: {
      text: {
        TITLE: 'Delete Contracts and Invoices',
        DESCRIPTION: 'You can delete contracts and invoices with AppZen unique contract ID or invioce ID.',
        HINT: 'Hint',
        HINT_DESCRIPTION: 'You can find AppZen unique contract ID or invoice ID from urls.',
        WARNING: 'Warning',
        DELETE_FILES_WARNING: 'Deletion of the following IDs has failed: ',
        CLOSE: 'Close',
        CANCEL: 'Cancel',
        SAVE: 'Save',
        CONFIRM_DELETE: 'Confirm Delete',
        DELETE_CONTRACTS_TITLE: 'Delete Contracts',
        DELETE_CONTRACTS_LABEL: 'Appzen Contract ID',
        DELETE_CONTRACTS_PLACEHOLDER: 'Comma-separated internal contract IDs',
        DELETE_INVOICES_TITLE: 'Delete Invoices',
        DELETE_INVOICES_LABEL: 'Appzen Invoice ID',
        DELETE_INVOICES_PLACEHOLDER: 'Comma-separated internal invoice IDs',
        DELETE_FORM_WARNING: '{label} is required.',
        MODAL_TITLE: 'Delete Confirmation',
        MODAL_DESCRIPTION: 'Please confirm that you are deleting the following IDs:',
        COPY_TO_CLIPBOARD: 'Copy IDs to clipboard',
        COPIED_TO_CLIPBOARD: 'Copied to clipboard',
      },
      api: {
        API_DELETE_FILES_ERROR: 'Error deleting files.',
        API_DELETE_FILES_SUCCESS: 'Successfully deleted {product} #: {ids}',
      },
    },
    uploadFiles: {
      text: {
        TITLE: 'Upload Documents',
        DOCUMENT_TYPE: 'Document Type',
        SELECT_DOCUMENT_TYPE: 'Select Document Type',
        INVOICE: 'Invoice',
        CONTRACT: 'Contract',
        PO: 'PO',
        OTHER: 'Other',
        CANCEL: 'Cancel',
        UPLOAD: 'Upload',
        CONFIRM: 'Confirm',
        REMOVE_FILE: 'Remove this File',
        UPLOADING: 'Uploading...',
        UPLOAD_MESSAGE_1: 'We are uploading the files now, and it may take awhile.',
        UPLOAD_MESSAGE_2: 'You can still access other pages if needed.',
        UPLOAD_FAILED: 'Upload Failed',
        UPLOAD_SUCCESSFUL: 'Upload Successful',
        UPLOAD_MORE_FILES: 'Upload More Files',
        CANCEL_UPLOAD: 'Cancel Upload',
        CANCEL_UPLOAD_CONFIRM_MESSAGE: 'Are you sure you want to cancel uploading documents?',
        REUPLOAD_FILES_MESSAGE: '{filesNo} Upload(s) Failed. Please reupload the following files:',
      },
      api: {
        UPLOAD_DOCUMENT_ERROR: 'We encountered an error while uploading the documents. Please upload all the files again.',
        UPLOAD_DOCUMENT_SUCCESS: 'Files uploaded successfully. Thanks!',
      },
    },
  },
  apReports: {
    api: {
      GENERATE_REPORT_SUCCESS: 'Your AP Audit report generation request has been submitted',
      GENERATE_REPORT_ERROR: 'Encountered an error when creating the report',
      GET_AP_REPORT_TYPES_ERROR: 'Error retrieving Report Types',
      REFRESH_REPORT_ERROR: 'Encountered an error when refreshing the report',
    },
    text: {
      ACTION: 'Action',
      AP_AUDIT_REPORT_GENERATION: 'AP Audit Report Generation',
      CANCEL: 'Cancel',
      CREATE: 'Create',
      CREATION_TIME: 'Creation Time',
      DATE_RANGE: 'Date Range',
      FAIL_REASON: 'Fail Reason',
      LOW: 'Low',
      MEDIUM: 'Medium',
      HIGH: 'High',
      GENERATE_REPORT: 'Generate Report',
      REPORT_TYPE: 'Report Type',
      STATUS: 'Status',
    },
  },
  aaaReports: {
    api: {
      GENERATE_REPORT_SUCCESS: 'Your Autonomous AP Audit report generation request has been submitted',
      GENERATE_REPORT_ERROR: 'Encountered an error when creating the report',
      REFRESH_REPORT_ERROR: 'Encountered an error when refreshing the report',
      GET_AAA_REPORTS_ERROR: 'Encountered an error when fetching Autonomous AP Reports',
      GET_INVOICE_STATE_CHOICES_ERROR: 'Encountered an error when fetching invoice state choices',
      GENERATE_EMPLOYEE_REPORT_SUCCESS: 'Your Employee details Autonomous AP report generation request has been submitted',
      GET_RECORD_TYPE_ERROR: 'Encountered an error when fetching Autonomous AP record type choices',
    },
    text: {
      ACTION: 'Action',
      AAA_REPORT_GENERATION: 'Employee Details Report Generation',
      CANCEL: 'Cancel',
      CREATE: 'Create',
      CREATION_TIME: 'Creation Time',
      INVOICE_DATE_RANGE: 'Invoice Date',
      INVOICE_PROCESSING_AT_RANGE: 'Processing At',
      FAIL_REASON: 'Fail Reason',
      LOW: 'Low',
      MEDIUM: 'Medium',
      HIGH: 'High',
      GENERATE_REPORT: 'Generate Report',
      REPORT_TYPE: 'Report Type',
      STATUS: 'Status',
      APPROVED: 'Approved',
      PROCESSED: 'Processed',
      REJECTED: 'Rejected',
      ALL: 'All',
    },
  },
  contractDetails: {
    page: {
      TITLE: 'Contract Details',
    },
    terms: {
      NOT_FOUND: 'Not Found',
      NOT_APPLICABLE: 'Not Applicable',
      PAGE: 'Page',
      TABLE: 'Table',
      VALUE_NOT_FOUND: 'Value not found',
    },
    api: {
      CONRACT_DETAILS_ERROR: 'Encountered an error while retrieving contract details.',
    },
    text: {
      CONTRACT: 'Contract',
      CONTRACT_FILE_NAME: 'Contract File Name',
      CONTRACT_TERMS: 'Contract Terms',
      EDIT_TERMS: 'Edit Terms',
      EXPIRATION_DATE: 'Expiration Date',
      SUPPLIER_NAME: 'Supplier Name',
      AUDIT_RESULTS: 'Audit Result(s)',
      PROCESSING: 'Processing',
      PROCESSING_CONTRACT: 'We are extracting details and terms from your contract.',
      LOW_RISK_MESSAGE: 'No risk detected on this contract.',
    },
    buttons: {
      SHOW_ADDITIONAL_RESULTS: 'Show additional results',
      HIDE_ADDITIONAL_RESULTS: 'Hide additional results',
    },
  },
  contractWorkbench: {
    api: {
      GET_CONTRACT_FILTERS_ERROR: 'Encountered an error while retrieving contract filters.',
      GET_CONTRACTS_ERROR: 'Encountered an error while retrieving contracts.',
    },
    dropdown: {
      label: {
        EXPIRES_WITHIN: 'Expires Within',
      },
    },
    header: {
      TITLE: 'All Contracts',
    },
    tab: {
      ALL_CONTRACTS: 'All Contracts',
    },
    table: {
      AUDIT_STATUS: 'Status',
      CONTRACT_FILE_NAME: 'Contract File Name',
      CONTRACT_TYPE: 'Contract Type',
      CONTRACTS: 'Contracts',
      EFFECTIVE_DATE: 'Effective Date',
      EXPIRATION_DATE: 'Expiration Date',
      SUPPLIER_NAME: 'Supplier Name',
    },
    page: {
      TITLE: 'Contract Workbench',
    },
    text: {
      CANCEL: 'Cancel',
      OKAY: 'Okay',
      PROCESSING_DOCUMENTS: 'Processing Documents',
      PROCESSING_UPLOAD_CONTRACT_MSG_1: 'We are extracting details and terms from your contracts and doing all the hard work for you.',
      PROCESSING_UPLOAD_CONTRACT_MSG_2: 'Sit back and relax.  We will let you know once it\'s done.',
      UPLOAD: 'Upload',
      UPLOAD_CONTRACT: 'Upload Contract',
      UPLOAD_CONTRACT_COMPLETE: 'Upload Contract Complete',
      UPLOAD_CONTRACT_ERROR: 'We encountered an error while trying to upload the files. Please try again later.',
      UPLOAD_CONTRACT_IN_PROGRESS_MSG_1: 'We are uploading the files now, and it may take a while.',
      UPLOAD_CONTRACT_IN_PROGRESS: 'Uploading in progress',
      UPLOAD_FAILED: 'Upload(s) Failed',
    },
  },
  invoiceWorkbench: {
    page: {
      TITLE: 'Invoice Workbench',
    },
    header: {
      TITLE: 'All Audit Reports',
    },
    tab: {
      EXPENSE: 'Expense',
      INVOICE: 'Invoice',
    },
    table: {
      SUPPLIER_INVOICE_NUMBER: 'Supplier Invoice Number',
      SUPPLIER_NAME: 'Supplier Name',
      DUE_DATE: 'Due Date',
      INVOICE_AMOUNT: 'Invoice Amount',
      REQUESTOR: 'Requestor',
      AUDIT_STATUS: 'Audit Status',
      WORKFLOW_STATUS: 'Workflow Status',
      ORGANIZATION: 'Organization',
    },
    api: {
      GET_INVOICE_FILTERS_ERROR: 'Encountered an error while retrieving invoice filters.',
      GET_INVOICES_ERROR: 'Encountered an error while retrieving invoices',
      GET_WORKBENCH_TILES_ERROR: 'Encountered an error while retrieving invoice tiles',
    },
  },
  invoiceDetails: {
    api: {
      AUDITOR_ACTION_ERROR: 'Error when actioning invoice',
      AUDITOR_ACTION_SUCCESS: 'Successfully actioned invoice',
      GET_EXCLUDE_REASON_ERROR: 'Error when retrieving exclude reasons',
      GET_INVOICE_HEADER_AUDIT_RESULTS_ERROR: 'Encountered an error while retrieving header audit results',
      GET_LOGICAL_INVOICE_DETAILS_ERROR: 'Encountered an error while retrieving invoice details',
      GET_INVOICE_AUDIT_HISTORY_ERROR: 'Encountered an error while retrieving invoice audit history',
      GET_PURCHASE_ORDER_LINES_ERROR: 'Encountered an error while retrieving purchase order lines',
      RE_RUN_AUDIT_INFO: 'Re-running audit for this invoice',
      RE_RUN_AUDIT_ERROR: 'Encountered an error while re-running audit for this invoice',
    },
    audit: {
      SHOW_MORE_RISK_RESULTS: 'Show more risk results',
    },
    modals: {
      OPEN_MODAL_TEXT: 'Click here for more info',
      BILL_FROM: 'Bill from',
      DUE_ON: 'due on',
      RISK_REASON: 'Risk Reason',
      VIEW_PRICING_TABLES: 'View Pricing Tables',
      anomalyModal: {
        TOTAL_AMOUNT: 'Total Amount:',
        INVOICE_NUMBER: 'Inovice Number',
        INVOICE_LINE: 'Invoice Line',
        INVOICE_SUBMISSION_DATE: 'Invoice Submission Date',
        AMOUNT: 'Amount',
      },
      duplicateInvoiceModal: {
        INVOICE: 'Invoice',
        DUPLICATE_INVOICE: 'Potential Duplicate Invoice',
        DUPLICATE_EXPENSE: 'Potential Duplicate Expense',
      },
      priceMismatch: {
        INVOICE_UNIT_PRICE: 'Invoice Unit Price',
        CONTRACT_ITEM: 'Contract Item',
        PAGE: 'Page',
        TABLE: 'Table',
        ITEM_PRICE: 'Item Price',
        EXPIRATION_DATE: 'Expiration Date',
        VIEW_PRICING_TABLE_BUTTON: 'View Pricing Table in Contract',
        VALUE_NOT_FOUND: 'Value not found',
        CLOSE: 'Close',
      },
    },
    page: {
      TITLE: 'Invoice Details',
    },
    tab: {
      INVOICE: 'Invoice',
      INVOICE_ATTACHMENT: 'Invoice Attachment',
      CONTRACT: 'Contract',
    },
    termLabel: {
      BILL_TO: 'Bill to',
      DUE_DATE: 'Due Date',
      INVOICE_AMOUNT: 'Invoice Amount',
      INVOICE_DATE: 'Invoice Date',
      INVOICE_NUMBER: 'Invoice #',
      ORGANIZATION: 'Organization',
      PAYMENT_TERM: 'Payment Term',
      PURCHASE_ORDER_NUMBER: 'Purchase Order Number',
      REQUESTOR: 'Requestor',
      SHIP_TO: 'Ship to',
      SUPPLIER: 'Supplier',
    },
    text: {
      AUDIT_IN_PROGRESS: 'Audit In Progress',
      DOWNLOAD_INVOICE: 'Download Invoice',
      NO_INVOICE: 'No invoice',
      NO_CONTRACT: 'No contract associated to this invoice',
      INVOICE_PROCESSING: 'Invoice attachment processing',
    },
    sectionLabel: {
      ELECTRONIC_INVOICE: 'Electronic Invoice Line Information',
      INVOICE_ATTACHMENT: 'Invoice Attachment',
    },
    table: {
      header: {
        COMMITTED_AMOUNT: 'Committed Amount',
        COMMITTED_QUANTITY: 'Committed QTY',
        DISCOUNT: 'Discount',
        INVOICE_LINE: 'Invoice Line',
        PRICE: 'Price',
        QUANTITY: 'QTY',
        SHIPPING_AND_HANDLING: 'Shipping & Handling',
        SUBTOTAL: 'Subtotal',
        TAX: 'Tax',
        TOTAL_AMOUNT: 'Total Amount',
        UNIT_OF_MEASURE: 'UOM',
        UNIT_PRICE: 'Unit Price',
      },
    },
    rightPanel: {
      text: {
        APPROVAL_REASON_QUESTION: 'Why are you approving this?',
        FALSE_POSITIVE_REASON_QUESTION: 'Why do you think risk is incorrect',
        INTERNAL_COMMENTS: 'Internal Comments',
        INVOICE_AUDIT_REVIEW: 'Invoice Audit Review',
        LOW_RISK_MESSAGE: 'No risk detected on this invoice.',
        PAGE: 'Page',
        AUDIT_RISK_MESSAGE: 'Please audit the following risk(s).',
        PROCESSING_STATUS_RISK_MESSAGE: 'Automated audit engine has not yet completed audit for this invoice.',
        CONTRACT_FILE_NAME: 'Contract File Name',
        VIEW_CONTRACT: 'View Contract',
      },
      buttons: {
        AUDIT_RESULT: 'Audit Result',
        APPROVE: 'Approve',
        CONTRACT: 'Contract',
        BACK_TO_AUDIT_RESULTS: 'Back to Audit Results',
        HISTORY: 'History',
        HIDE_ADDITIONAL_RESULTS: 'Hide additional results',
        REJECT: 'Reject',
        REVIEW: 'Review',
        RERUN_AUDIT: 'Re-run Audit',
        SHOW_ADDITIONAL_RESULTS: 'Show additional results',
        SUBMIT_REVIEW: 'Submit Review',
      },
    },
  },
  invoiceAuditHistory: {
    AT: 'at',
    SENT_FROM: 'Sent From:',
    BY: 'by',
    TO: 'to',
    RISK_SCORE: 'Risk Score:',
    AUDIT_STATUS: 'Audit Status:',
    WORKFLOW_STATUS: 'Workflow Status:',
    riskScores: {
      HIGH: 'High',
      MED: 'Med',
      LOW: 'Low',
    },
    NO_DATA: 'No Data Available',
  },
  editContract: {
    ADD_COLUMN: 'Add Column',
    ADD_PRICING_DATA: 'Add Pricing Data',
    ADD_PRICING_TABLE: 'Add Pricing Table',
    ADD_ROW: 'Add Row',
    CHOOSE_TABLE_OPTIONS: 'Choose Table Options',
    COMPLETE_REVIEW: 'Complete Review',
    COMPELTE_DATA_REVIEW: 'Complete Data Review',
    COLUMN: 'Column',
    EDIT_PAGE: 'Edit Page',
    EDIT_PRICING_TABLE: 'Edit Pricing Table',
    EDIT_TERMS: 'Edit Terms',
    FROM: 'from',
    HEADER_ORIENTATION: 'HEADER ORIENTATION',
    HIDE_TABLE: 'Hide Table',
    HIDDEN: 'Hidden',
    HUMAN_VERIFIED: 'Human Verified',
    LAST_EDITED_BY: 'Last edited by',
    MAPPED: 'Mapped',
    NEITHER: 'Neither',
    NOT_APPLICABLE: 'Not Applicable',
    NOT_FOUND_ADD_NEW: 'Not Found -- Add New',
    OCR: 'OCR',
    ON: 'on',
    PAGE_NUMBER: 'Page # ',
    PRICING_TABLE: 'Pricing Table',
    RESET: 'Reset',
    RERUN_AUDIT: 'Re-run Audit',
    ROW: 'Row',
    QUALIFIER_COLUMN: 'Qualifier Column',
    QUALIFIER_ORIENTATION: 'QUALIFIER ORIENTATION',
    QUALIFIER_ROW: 'Qualifier Row',
    SAVE_PRICING_TABLE: 'Save',
    SHOW_TABLE: 'Show Table',
    TABLE: 'Table',
    TABLE_NUMBER: 'Table # ',
    TABLE_OPTIONS: 'Table Options',
    TOGGLE_PANEL: 'Toggle Panel',
    TYPE: 'Type ',
    UPDATE: 'Update',
    VERIFIED: 'Verified',
    VERIFY: 'Verify',
    VALUE_NOT_FOUND: '- Value is not found -',
    VALUE_IS_NOT_FOUND: 'Value is not found',
    VIEW_PAGE: 'View Page',
    VIEW_PRICING_TABLE: 'View Pricing Table',
    messages: {
      ACTION_SUCCESS: 'Successfully updated contract status',
      ACTION_ERROR: 'Error updating contract status',
      AUDIT_IN_PROGRESS: 'Audit in progress',
      GET_CONTRACT_ERROR: 'Error retrieving contract',
      GET_PRICING_TABLE_ERROR: 'Error retrieving pricing table',
      GET_PRICING_TABLE_METADATA_ERROR: 'Error retrieving table headers',
      RE_RUN_CONTRACT_SUCCESS: 'Re-running audit for this contract',
      RE_RUN_CONTRACT_ERROR: 'Error re-running audit for this contract',
      SAVE_PRICING_TABLE_SUCCESS: 'Successfully saved pricing table',
      SAVE_PRICING_TABLE_ERROR: 'Error saving pricing table',
      UPDATE_CONTRACT_TERM_ERROR: 'Error updating contract term',
    },
  },
  invoiceReview: {
    api: {
      ACTION_ERROR: 'Error when actioning the invoice',
    },
    tab: {
      INVOICE: 'Invoice',
      INVOICE_ATTACHMENT: 'Invoice Attachment',
    },
    text: {
      APPROVE: 'Approve',
      DUE_DATE: 'Due Date',
      INVOICE_APPROVAL: 'Invoice Approval',
      INVOICE_DATE: 'Invoice Date',
      INVOICE_NUMBER: 'Invoice #',
      INVOICE_REVIEW: 'Invoice Review',
      INVOICE_SUMMARY: 'Invoice Summary',
      INVOICE_UNIT_PRICE: 'Invoice Unit Price',
      LEARN_MORE: 'Learn More',
      LINE: 'Line',
      PLEASE_REVIEW_AND_APPROVE_INVOICE: 'Please review and approve this invoice',
      REJECT: 'Reject',
      TOTAL_AMOUNT: 'Total Amount',
      USER_APPROVAL: 'You have approved the invoice',
      USER_REJECTION: 'You have rejected the invoice',
    },
  },
  expense: {
    api: {
      GET_EXPENSE_DETAILS_ERROR: 'Encountered an error while retrieving expense report.',
    },
  },
  supplierDetails: {
    api: {
      SAVE_SUPPLIER_NOTE_ERROR: 'Error saving supplier note',
      SAVE_SUPPLIER_NOTE_SUCCESS: 'Successfully saved supplier note',
    },
    page: {
      TITLE: 'Supplier Details',
    },
    text: {
      ACTIVE: 'Active',
      ADDRESS: 'Address',
      ADDRESS_AND_CONTACT_INFO: 'Address and Contact Info',
      ADDRESS_NOT_FOUND: 'address not found',
      ALL_CONTRACTS: 'All Contracts',
      ALL_INVOICES: 'All Invoices',
      CANCEL: 'Cancel',
      CONTACT_NAME: 'Contact Name',
      DETAILS: 'Details',
      DOCUMENTS_NOT_FOUND: 'Documents not found',
      EMAIL_ADDRESS: 'Email Address',
      INACTIVE: 'Inactive',
      NOT_AVAILABLE: 'Not Available',
      NOTE: 'Note',
      PHONE_NUMBER: 'Phone Number',
      RESIDENTIAL: 'Residential',
      SAVE: 'Save',
      SUPPLIER_DOCUMENT_INFO: 'Supplier Document Info',
    },
  },
  touchlessAPWorkbench: {
    page: {
      TITLE: 'Touchless AP Workbench',
    },
    header: {
      TITLE: 'Touchless AP Workbench',
    },
    tabs: {
      ALL_INVOICES: 'All Invoices',
      UNPROCESSED_INVOICES: 'Unprocessed invoices ({numberOfInvoices})',
    },
    table: {
      AUDIT_STATUS: 'Validation Status',
      DUE_DATE: 'Due Date',
      INVOICE_AMOUNT: 'Invoice Amount',
      ORGANIZATION: 'Organization',
      SUBMISSION_DATE: 'Submission Date',
      SUPPLIER_INVOICE: 'Supplier Invoice',
      SUPPLIER_NAME: 'Supplier Name',
      WORKFLOW_STATUS: 'Workflow Status',
      RECEIVED_DATE: 'Received Date',
      FILENAME: 'Filename',
      SOURCE: 'Source',
    },
    text: {
      DAYS: 'days',
      HOURS: 'hr',
    },
    api: {
      GET_TOUCHLESS_INVOICES_ERROR: 'Encountered an error while retrieving invoices',
      GET_TOUCHLESS_INVOICE_HISTORY_ERROR: 'Encountered an error while retrieving invoice history',
      GET_TOUCHLESS_UNPROCESSED_INVOICES_ERROR: 'Encountered an error while retrieving unprocessed invoices',
    },
  },
  touchlessAPDetails: {
    api: {
      AUDITOR_ACTION_ERROR: 'Error when actioning invoice',
      AUDITOR_ACTION_SUCCESS: 'Successfully actioned invoice',
      AUDIT_TOUCHLESS_INVOICE_ERROR: 'Error auditing invoice',
      AUDIT_TOUCHLESS_INVOICE_SUCCESS: 'Auditing invoice',
      GET_AUTOCOMPLETE_ERROR: 'Error retrieving autocomplete results',
      GET_APPROVERS_LIST_ERROR: 'Error while retrieving approvers list',
      GET_GL_CODES_VALIDATION_ERROR: 'Error while retrieving GL Codes level fields',
      GET_HEADER_LEVEL_VALIDATION_ERROR: 'Error while retrieving header level fields',
      GET_LINE_LEVEL_VALIDATION_ERROR: 'Error while retrieving line level information table',
      GET_TOUCHLESS_INVOICE_DETAILS_ERROR: 'Error retrieving invoice details.',
      SAVE_GL_CODES_VALIDATION_ERROR: 'Error while saving GL Codes validations',
      SAVE_HEADER_LEVEL_VALIDATION_ERROR: 'Error while saving header level validations',
      SAVE_LINE_LEVEL_VALIDATION_ERROR: 'Error while saving line level information table',
      SAVE_GL_CODES_VALIDATION_SUCCESS: 'Successfully saved GL Codes validations',
      SAVE_HEADER_LEVEL_VALIDATION_SUCCESS: 'Successfully saved header level validations',
      SAVE_LINE_LEVEL_VALIDATION_SUCCESS: 'Successfully saved line level information table',
      VOID_INVOICE_SUCCESS: 'Succesfully voided invoice',
      VOID_INVOICE_ERROR: 'Error while voiding the invoice',
    },
    page: {
      TITLE: 'Touchless AP Details',
    },
    header: {
      INVOICE_NUMBER: 'Invoice #',
    },
    text: {
      AUDIT_RISK_MESSAGE: 'Please validate the following risk(s).',
      ACCOUNT_CODE: 'Account Code',
      APPROVER: 'Approver ({count})',
      COMMENT: 'Comment',
      FIELDS: 'field(s)',
      HISTORY: 'History',
      INVOICE_ATTACHMENT: 'Invoice Attachment',
      INVOICE_AUDIT_RESULTS: 'Invoice Validation Results',
      INVOICE_AUDIT_REVIEW: 'Invoice Validation Review',
      INVOICE_DETAILS_VERIFICATION: 'Invoice Details Verification',
      INVOICE_LINE_INFORMATION: 'Invoice Line Information',
      IS_THE_RISK_CORRECT: 'Is the Risk correct?',
      MDOAL_LEAVE_MESSAGE: 'If you leave this page all unsaved changes will be lost. Are you sure you want to leave this page?',
      MODAL_LEAVE_TITLE: 'You have unsaved changes on this page',
      NO_INVOICE: 'No Invoice',
      EXTRACTION_IN_PROGRESS: 'Extraction in progress',
      PENDING_ENTRY: 'Pending data validation',
      PLEASE_VERIFY: 'Please verify the following',
      PROCESSING_STATUS_RISK_MESSAGE: 'Automated validation engine has not yet completed validation for this invoice.',
      SUGGESTIONS: 'Suggestions',
    },
    tabs: {
      VALIDATION_RESULT: 'Validation Result',
      CONTRACT: 'Contract',
      DATA: 'Data',
      HISTORY: 'History',
    },
    buttons: {
      ADD_ROW: 'Add Row',
      ADD_COLUMN: 'Add Column',
      ACCOUNT_CODE: 'GL Code',
      BACK_TO_AUDIT_RESULTS: 'Back to Validation Results',
      CLOSE: 'Close',
      MODAL_LEAVE_CANCEL: 'No, stay on page',
      MODAL_LEAVE_CONFIRM: 'Yes, leave and unsave',
      PENDING_ENTRY: 'Pending data validation',
      REJECT: 'Reject',
      REVIEW: 'Review',
      REQUEST_MORE_INFO: 'Request More Info',
      SEND_FOR_APPROVAL: 'Send For Approval',
      SAVE: 'Save',
      VOID: 'Void',
    },
  },
  userManagement: {
    api: {
      DEFAULT_ORG_MISSING_ERROR: 'Default organization is required',
      GET_ROLES_ERROR: 'Error retrieving roles',
      GET_USERS_ERROR: 'Error retrieving users',
      MISSING_ORGANIZATIONS_ERROR: 'Please select at least one organization',
      SEARCH_USER_ERROR: 'Error searching for user',
      UPDATE_ORG_SUCCESS: 'Successfully assigned organizations to user.',
      UPDATE_ORG_ERROR: 'Encountered an error while updating user organization info.',
      UPDATE_USER_ROLES_ERROR: 'Error updating user roles',
    },
    text: {
      ADD_ORGANIZATION: 'Add Organization',
      ADD_ROLE: 'Add Role',
      ADD_USER: 'Add User',
      APPZEN_USER_CONFIGURATION: 'AppZen_User_Configuration',
      CANCEL: 'Cancel',
      DEFAULT_ORGANIZATION: 'Default Organization',
      EDIT_ORGANIZATION: 'Edit Organization',
      EDIT_ROLE: 'Edit Role',
      EDIT_USER: 'Edit User',
      EXPORT_USER_LIST: 'Export User List',
      LAST_UPDATED: 'Last Updated',
      LOGIN_ID: 'Login ID',
      MORE: 'More',
      NO_SELECTED_ORGS_LABEL: 'Please assign at least one organization below to the user before setting default.',
      ORGANIZATION_ACCESS: 'Organization Access',
      ORGANIZATIONS: 'Organization(s)',
      OTHER_ORGANIZATIONS: 'Other Organization(s)',
      ROLE: 'Role',
      UPDATE: 'Update',
      SELECT_DEFAULT_ORG: 'Select Default Org',
      USER_EMAIL: 'User Email',
      USER_NAME: 'User Name',
      USER_MANAGEMENT: 'User Management',
      USER_ROLE: 'User Role',
    },
  },
};
