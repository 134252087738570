import { updateObject } from '@az/utility';
import * as actions from '../../store/actions/actionTypes';

const initialState = {
  allRoles: [],
  apRoles: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ALL_ROLES_SUCCESS:
      return updateObject(state, {

      });
    case actions.GET_ALL_AP_ROLES_SUCCESS:
      return updateObject(state, {
        apRoles: action.payload.data,
      });
    default:
      return state;
  }
};

export default userReducer;
