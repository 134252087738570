import { fetcher } from './helper';
import api from './api';
import qs from 'qs';
import {
  generateQueryURLWithParams
} from '@az/utility';

export const coreService = {
  getUserDetails() {
    const requestUrl = 'rest/v1/customers/user_details';

    const config = {
      method: 'GET',
      mode: 'cors'
    };

    return fetcher(`${api.expenseBaseUrl}${requestUrl}`, config)
      .then(response => response)
      .catch(error => error);
  },

  getApplicationConfigValues(...defaultAppFlags) {
    const url = `${api.expenseBaseUrl}rest/expenseservice/application/config`;
    const request = generateQueryURLWithParams(url, {configValues : defaultAppFlags.join(',')});

    const config = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'accept': 'application/json'
      }
    };

    return fetcher(request, config)
      .then(response => response)
      .catch(error => error);
  },

  getConfigValues() {
    const requestUrl = 'rest/expenseservice/getconfigvalues';
    const requestParams = {
      appId: 1003
    };
    const config = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: qs.stringify(requestParams)
    };

    return fetcher(`${api.expenseBaseUrl}${requestUrl}`, config)
      .then(response => response)
      .catch(error => error);
  },

  getProductsList(customerId) {
    const config = {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return fetcher(`${api.expenseBaseUrl}rest/v1/customers/${customerId}/products`, config)
      .then(response => response)
      .catch(error => {
        return error;
      });
  },

  getUsersNotifications() {
    const config = {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      disableAuthCheck: true,
    };

    return fetcher(`${api.expenseBaseUrl}rest/v1/mastermind/mastermind_approver/notifications`, config, false, false)
      .then(response => response)
      .catch(error => {
        return error;
      });
  },

  switchToAdminUser() {
    const requestUrl = 'rest/appZenSupportService/switchtoadminuser';
    const config = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return fetcher(`${api.expenseBaseUrl}${requestUrl}`, config)
      .then(res => res)
      .catch(error => error);
  },

  getCustomerOrganizations() {
    const config = {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return fetcher(`${api.expenseBaseUrl}rest/customer_organization`, config)
      .then(response => response)
      .catch(error => {
        return error;
      });
  },

  getCustomerName(prefixUrl) {
    const config = {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
    };
    
    return fetcher(`${prefixUrl}rest/users/getCustomerName`, config, false);
  },

  switchAccount(userId, customerId) {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return fetcher(
      `${api.expenseBaseUrl}rest/appZenSupportService/multiple_account_login/access_account?userId=${userId}&customerId=${customerId}`,
      config,
      true,
    )
      .then((response) => response)
      .catch((error) => error);
  },

  getZendeskToken() {
    const config = {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
    };
    
    return fetcher(`${api.expenseBaseUrl}rest/v1/zendesk/token`, config, false);
  },
};
