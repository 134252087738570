import React, {Component} from 'react';

import './Ribbon.scss';

class Ribbon extends Component {
  render() {
    return (
      <div className="ribbon">
        <div className="ribbon__icon">
          <i className="fas fa-sync-alt"/>
        </div>
      </div>
    );
  }
}

export default Ribbon;
