import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { uiConfig } from '@az/common-configs';
import {
  capitalizeAllWords, isArrayWithData, isDefined, removeSnakeCase,
} from '@az/utility';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import { inlDateFormat } from '@az/common-configs/src/globalConfig';
import { Button } from '@az/components';
import displayLocalDateObj from '../../../config/utility';

const { COLOR } = uiConfig;

const ParameterLabel = styled.span`
  color: ${COLOR.DEFAULTS.LABEL_TEXT};
  font-style: italic;
  margin-right: 5px;
`;

const ParameterRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ParameterValue = styled.span`
  color: ${COLOR.DEFAULTS.LABEL_TEXT};
`;

const Row = styled.div`
  align-items: center;
  display: flex;
`;

const StyledButton = styled(Button)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const StyledIcon = styled.div`
  align-items: center;
  color: ${COLOR.BLUE3[7]};
  display: flex;
  height: 100%;
  margin-right: 8px;

  svg {
    font-size: 17px;
  }
`;

const TableRow = styled.div`
  align-items: start;
  display: flex;
  padding: 5px 5px 10px 5px;

  &:hover {
    background-color: ${COLOR.BLUE3[1]};
  }
`;

const TableCol = styled.div`
  color: ${({ color }) => (color)};
  display: flex;
  font-size: 12px;
  font-weight: 400;
  flex-direction: column;
  padding: 10px 20px 10px 0px;
  text-align: start;
  flex: ${({ colWidth }) => {
    switch (colWidth) {
      case 'sm':
        return '1';
      case 'md':
        return '2';
      case 'lg':
        return '3';
      default:
        return '1';
    }
  }};
`;

function ReportGenerationRow({
  handleDownloadReport, handleRefreshReport, handleRetryReport, report,
}) {
  const { inlDate, timestamp, timezone } = displayLocalDateObj(report.created_at);
  const { parameters } = report;

  const determineTextColor = (status = null) => {
    const lcStatus = isDefined(status) ? status.toLowerCase() : status;
    switch (lcStatus) {
      case 'in_queue':
        return `${COLOR.DEFAULTS.MEDIUM_RISK}`;
      case 'failed':
        return `${COLOR.DEFAULTS.HIGH_RISK}`;
      default:
        return `${COLOR.DEFAULTS.BODY_TEXT}`;
    }
  };

  return (
    <TableRow data-az-label="AAA Report Generation Row">
      <TableCol data-az-label="Report Type" colWidth="lg">
        {report.report_type}
        {parameters && parameters.invoice_start_date && parameters.invoice_end_date && (
          <ParameterRow>
            <ParameterLabel>Invoice Date Range: </ParameterLabel>
            <ParameterValue>
              {`${parameters.invoice_start_date} to ${parameters.invoice_end_date}`}
            </ParameterValue>
          </ParameterRow>
        )}
        {parameters && parameters.processing_at_start_date && parameters.processing_at_end_date && (
          <ParameterRow>
            <ParameterLabel>Processing At: </ParameterLabel>
            <ParameterValue>
            {`${parameters.processing_at_start_date} to ${parameters.processing_at_end_date}`}
            </ParameterValue>
          </ParameterRow>
        )}
        {parameters && isArrayWithData(parameters.risk_scores) && (
          <ParameterRow>
            <ParameterLabel>Risk: </ParameterLabel>
            <ParameterValue>
              {`${capitalizeAllWords(parameters.risk_scores.join(', '))}`}
            </ParameterValue>
          </ParameterRow>
        )}
        {parameters && parameters.appzen_invoice_state && (
          <ParameterRow>
            <ParameterLabel>Invoice State: </ParameterLabel>
            <ParameterValue>
              {`${parameters.appzen_invoice_state}`}
            </ParameterValue>
          </ParameterRow>
        )}
        {parameters && isArrayWithData(parameters.custom_filters) && (
          parameters.custom_filters.map((customFilter) => (
            <ParameterRow key={customFilter.key}>
              <ParameterLabel>
                {capitalizeAllWords(removeSnakeCase(customFilter.key))}
              </ParameterLabel>
              <ParameterValue>
                {customFilter.value}
              </ParameterValue>
            </ParameterRow>
          ))
        )}
      </TableCol>
      <TableCol data-az-label="Creation Time" colWidth="lg" color={determineTextColor()}>{`${inlDate} ${timestamp} ${timezone}`}</TableCol>
      <TableCol data-az-label="Status" colWidth="sm" color={determineTextColor(report.status)}>{report.status}</TableCol>
      <TableCol data-az-label="Fail Reason" colWidth="lg" color={determineTextColor()}>{report.fail_reason}</TableCol>
      <TableCol data-az-label="Action" colWidth="md" color={determineTextColor()}>
        {report.status && report.status.toLowerCase() === 'done' && (
          <>
            {
              report.formatDetailsList.map(({ report_format: reportFormat, report_id: reportId, s3_key: s3Key }) => (
                <StyledButton dataAzLabel="Download" classes="button--neutral" clicked={() => (handleDownloadReport(reportFormat, reportId, s3Key))}>
                  <Row>
                    <StyledIcon>
                      <GetAppIcon />
                    </StyledIcon>
                    <span>Download</span>
                  </Row>
                </StyledButton>
              ))
            }
          </>
        )}

        {report.status && (report.status.toLowerCase() === 'in_queue' || report.status.toLowerCase() === 'pending') && (
          <StyledButton dataAzLabel="Refresh" classes="button--neutral" clicked={() => (handleRefreshReport(report.report_id))}>
            <Row>
              <StyledIcon>
                <RefreshIcon />
              </StyledIcon>
              <span>Refresh</span>
            </Row>
          </StyledButton>
        )}

        {report.status && report.status.toLowerCase() === 'failed' && (
          <StyledButton dataAzLabel="Refresh" classes="button--neutral" clicked={() => (handleRetryReport(report.report_id))}>
            <Row>
              <StyledIcon>
                <RefreshIcon />
              </StyledIcon>
              <span>Retry</span>
            </Row>
          </StyledButton>
        )}
      </TableCol>
    </TableRow>
  );
}

ReportGenerationRow.propTypes = {
  handleDownloadReport: PropTypes.func,
  handleRefreshReport: PropTypes.func,
  handleRetryReport: PropTypes.func,
  report: PropTypes.object,
};

ReportGenerationRow.defaultProps = {
  handleDownloadReport: null,
  handleRefreshReport: null,
  handleRetryReport: null,
  report: {},
};

export default ReportGenerationRow;
