import {
  call,
  put,
  takeLatest,
  all,
  takeEvery,
  select,
} from 'redux-saga/effects';
import * as actions from '../actions/actionTypes';
import * as actionCreators from '../actions/action-coreService';
import { coreService } from '../service/api-coreService';
import apiUserLogin from '../../featureComponents/Login/LoginWrapper/api-login';
import {
  isArrayWithData,
  sortDataInObjects,
  getConfigValue,
  isDefined,
} from '@az/utility';
import api from '../service/api';
import { uiConfig } from '@az/common-configs';
import { authStorage } from '@az/utility';
import store from '../../store';
const { DEFAULT_SESSION_TIME_IN_MINUTES, DEFAULT_SESSION_COUNTDOWN_TIME_IN_SECONDS } = uiConfig;

function getOrganizationLookupValues(customerOrganizations) {
  const lookupData = {
    currencyCodes: [],
    organizations: [],
  };

  const currencies = [...new Set(customerOrganizations.map((organization) => organization.org_currency))];

  currencies.sort().forEach((currency) => {
    lookupData.currencyCodes.push({ id: currency, text: currency });
  });

  if (isArrayWithData(customerOrganizations)) {
    customerOrganizations.forEach((organization) => {
      lookupData.organizations.push({
        id: organization.az_customer_org_id,
        text: organization.org_name,
      });
    });

    lookupData.organizations = sortDataInObjects(lookupData.organizations, 'text');
  }

  return lookupData;
}

function* initialLoad() {
  try {
    yield put(actionCreators.initialLoadStart());

    const applicationFlags = ['DEFAULT_ENABLE_TRANSLATIONS_OVERLAY', 'DEFAULT_ENABLE_RISK_HIGHLIGHTING', 'FETCH_MASTERMIND_RULE', 'RETAIN_AUDITOR_FEEDBACK', 'TEAM_INTELLIGENCE_ENABLED', 'INVOICE_REPORT_GENERATION_WITH_DELAY', 'INTEGRATIONS_MENU_ENABLED', 'SHOW_LEGACY_AUDITOR_DASHBOARD', 'SHOW_LEGACY_EMPLOYEE_DASHBOARD'];

    const { userDetails, customerConfigValues, customerOrganizations, applicationConfigValues} = yield all({
      userDetails: call(coreService.getUserDetails),
      customerConfigValues: call(coreService.getConfigValues),
      customerOrganizations: call(coreService.getCustomerOrganizations),
      applicationConfigValues: call(coreService.getApplicationConfigValues, ...applicationFlags)
    });

    if (userDetails.status === 200
      && customerConfigValues.status === 200
      && customerOrganizations.status === 200
      && applicationConfigValues.status === 200
    ) {
      const organizationLookupValues = getOrganizationLookupValues(customerOrganizations.data);

      yield put(actionCreators.initialLoadSuccess({
        userDetails,
        customerConfigValues,
        applicationConfigValues,
        ...organizationLookupValues,
        isAppZenSuperAdminLogin: !!userDetails.data.emulated_by_appzen_super_admin,
        isConcurPartnerAdmin: !!userDetails.data.emulated_by_concur_partner_admin,
        isEmulatedByEmulator: !!userDetails.data.emulated_by_emulator,
        isUserEmulated: !!userDetails.data.emulated_by_appzen_super_admin
          || !!userDetails.data.emulated_by_concur_partner_admin || !!userDetails.data.emulated_by_emulator,
        isTiEnabledForCustomer: applicationConfigValues?.data?.TEAM_INTELLIGENCE_ENABLED?.toLowerCase() === 'true',
        isIntegrationsMenuEnabled: applicationConfigValues?.data?.INTEGRATIONS_MENU_ENABLED?.toLowerCase() === 'true',
        isFetchMastermindRuleEnabled: applicationConfigValues?.data?.FETCH_MASTERMIND_RULE?.toLowerCase() === 'true',
        applicationConfig: applicationConfigValues?.data,
      }));
    } else {
      yield put(actionCreators.initialLoadFail(null));
    }
  } catch (e) {
    yield put(actionCreators.initialLoadFail(e));
  }
}

function setTokenValueInLocalStorage(tokenResponse) {
  authStorage.setItem('APPZEN_JWTTOKEN', tokenResponse.token);
  authStorage.setItem('APPZEN_OKTATOKEN', tokenResponse['okta-refresh-token'] || '');
  authStorage.setItem('APPZEN_JWTTOKEN_FIAT', tokenResponse.fiat || '');
  authStorage.setItem('APPZEN_JWTTOKEN_IAT', tokenResponse.iat || '');
  authStorage.setItem('APPZEN_JWTTOKEN_EXP', tokenResponse.exp || '');
  authStorage.removeItem('session');
}

function getTokenExpirationTime() {
  const issuedAt = authStorage.getItem('APPZEN_JWTTOKEN_IAT');
  const expiration = authStorage.getItem('APPZEN_JWTTOKEN_EXP');

  let issuedAtDate = null;
  let expirationDate = null;

  let tokenLifeTimeInSeconds = null;
  let tokenHalfLifeTimeInSeconds = null;
  let remainingExpirationTimeInSeconds = null;
  if (isDefined(issuedAt) && isDefined(expiration)) {
    issuedAtDate = new Date(issuedAt);
    expirationDate = new Date(expiration);
    tokenLifeTimeInSeconds = (expirationDate.getTime() - issuedAtDate.getTime()) / 1000;
    tokenHalfLifeTimeInSeconds = tokenLifeTimeInSeconds / 2;
    // Convert date to browser's local time zone to get the accurate difference between expiration date and current time
    const expirationDateInUTC = new Date(expirationDate - expirationDate.getTimezoneOffset() * 60000);
    remainingExpirationTimeInSeconds = (expirationDateInUTC.getTime() - new Date().getTime()) / 1000;
  }

  return [
    tokenHalfLifeTimeInSeconds, remainingExpirationTimeInSeconds,
  ];
}

function* startSessionTimer() {
  const getIsSessionAboutToExpired = (state) => state.core.isSessionAboutToExpired;
  const isSessionAboutToExpired = yield select(getIsSessionAboutToExpired);

  const getIsRequestSessionInProgress = (state) => state.core.isRequestSessionInProgress;

  if (!isSessionAboutToExpired) {
    const getCustomerDefaultSessionTimeInSec = (state) => state.core.customerDefaultSessionTimeInSec;
    let customerDefaultSessionTimeInSec = yield select(getCustomerDefaultSessionTimeInSec);

    if (!customerDefaultSessionTimeInSec) {
      let customerDefaultSessionTimeInMinutes;
      const getCustomerConfigValues = (state) => state.core.customerConfigValues;
      const customerConfigValues = yield select(getCustomerConfigValues);
      customerDefaultSessionTimeInMinutes = getConfigValue('SESSION_TIME_IN_MINUTE', customerConfigValues);
      if (!customerDefaultSessionTimeInMinutes
        || customerDefaultSessionTimeInMinutes > DEFAULT_SESSION_TIME_IN_MINUTES) {
        customerDefaultSessionTimeInMinutes = DEFAULT_SESSION_TIME_IN_MINUTES;
      }
      customerDefaultSessionTimeInSec = customerDefaultSessionTimeInMinutes * 60;
      yield put(actionCreators.setCustomerDefaultSessionTimeInSec(customerDefaultSessionTimeInSec));
    }
    const startTime = new Date();
    let remainingSessionTimeInSec = customerDefaultSessionTimeInSec;

    let [tokenHalfLifeTimeInSeconds, remainingExpirationTimeInSeconds] = getTokenExpirationTime();

    let timer = setInterval(async () => {
      if (remainingSessionTimeInSec > DEFAULT_SESSION_COUNTDOWN_TIME_IN_SECONDS && (isDefined(authStorage.getItem('APPZEN_JWTTOKEN')) || isDefined(authStorage.getItem('APPZEN_CSRFTOKEN')))) {
        const resetSessionInProgress = getIsRequestSessionInProgress;
        const currentTime = new Date();
        const deltaInSec = Math.floor((+currentTime - +startTime) / 1000);
        remainingSessionTimeInSec = customerDefaultSessionTimeInSec - deltaInSec;
        remainingExpirationTimeInSeconds -= 1;
  
        if (!resetSessionInProgress
            && isDefined(remainingExpirationTimeInSeconds)
            && isDefined(tokenHalfLifeTimeInSeconds)
            && remainingExpirationTimeInSeconds <= tokenHalfLifeTimeInSeconds) {
          store.dispatch(actionCreators.requestResetSessionStarted());
          const refreshTokenResponse = await call(apiUserLogin.refreshAuthToken);
          store.dispatch(actionCreators.requestResetSessionCompleted());
  
          if ((isDefined(refreshTokenResponse) && isDefined(refreshTokenResponse.token)) || authStorage.getItem('APPZEN_CSRFTOKEN')) {
            !authStorage.getItem('APPZEN_CSRFTOKEN') && setTokenValueInLocalStorage(refreshTokenResponse);
            [tokenHalfLifeTimeInSeconds, remainingExpirationTimeInSeconds] = getTokenExpirationTime();
          } else {
            remainingExpirationTimeInSeconds = tokenHalfLifeTimeInSeconds + 30;
          }
        }
      }

      if (remainingSessionTimeInSec <= DEFAULT_SESSION_COUNTDOWN_TIME_IN_SECONDS) {
        store.dispatch(actionCreators.setSessionIsAboutToExpire(true));
        clearInterval(timer);
        return;
      }
    }, 1000)
  }
}

function* requestResetSession() {
  yield call(coreService.getCustomerName, api.expenseBaseUrl);
  yield call(coreService.getCustomerName, api.apBaseUrl);

  yield put(actionCreators.requestResetSessionStarted());
  const refreshTokenResponse = yield call(apiUserLogin.refreshAuthToken);
  yield put(actionCreators.requestResetSessionCompleted());

  if (isDefined(refreshTokenResponse) && isDefined(refreshTokenResponse.token)) {
    setTokenValueInLocalStorage(refreshTokenResponse);
    yield put(actionCreators.startSessionTimer());
  }
}

export const coreServiceSaga = [
  takeLatest(actions.INITIAL_LOAD, initialLoad),
  takeLatest(actions.START_SESSION_TIMER, startSessionTimer),
  takeEvery(actions.REQUEST_RESET_SESSION, requestResetSession),
];
