import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { apiEndPointHostNames, uiConfig } from '@az/common-configs';
import {
  Autocomplete,
  AzDialog,
  AzDialogActions,
  AzDialogCloseButton,
  AzDialogContent,
  AzDialogTitle,
  Button,
  DateRangePicker,
} from '@az/components';
import { isArrayWithData, isDefined, createDropdownDataObj } from '@az/utility';
import moment from 'moment';
import WarningIcon from '@material-ui/icons/Warning';
import apiAAAReports from '../api-aaaReports';
import { getNotificationAction } from '../../../store/actions/action-coreService';

const { COLOR } = uiConfig;

const ContentWrapper = styled.div`
  min-height: 375px;
`;

const Label = styled.div`
  color: ${COLOR.DEFAULTS.LABEL_TEXT};
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.75px;
  margin-bottom: 5px;
  text-transform: uppercase;
  ${(props) => props.required ? 
    `&::after {
      color: ${COLOR.DEFAULTS.HIGH_RISK};
      content: ' *';
    };` : null}
`;

const ParamSection = styled.div`
  margin: 5px;
  margin-bottom: 40px;
`;

const Title = styled.div`
  color: ${COLOR.DEFAULTS.BODY_TEXT};
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  letter-spacing: 0.38px;
`;

const Note = styled.span`
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.38px;
  &::before {
    color: ${COLOR.DEFAULTS.HIGH_RISK};
    content: '*Note:  ';
  }
`;

const DropDownList = styled.div`
  margin: 5px;
  margin-bottom: 20px;
`;

const DisclaimerContainer = styled.div`
  background-color: #FFF3D0;
  margin-top: 20px;
  padding: 8px;
  display: flex;
`;

const DisclaimerText = styled.div`
  margin-bottom: 10px;
  font-size: 11px;
  font-weight: 400;
`;

const DisclaimerLinks = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Link = styled.a`
  text-decoration: none;
  color: ${COLOR.BLUE3[6]};
  font-weight: bold;
  font-size: 12px;
  &:hover {
     color: ${COLOR.BLUE3[6]};
     text-decoration: underline;
    }
`;

const RightLink = styled(Link)`
  margin-left: auto;
`;

const StyledWarningIcon = styled(WarningIcon)`
color: ${COLOR.GREY1[9]};
margin-right: 6px;
margin-top: 6px;
`;

function ReportGenerationModal({ generateReport, handleCloseModal, userOrganizationId }) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [pendingParams, setPendingParams] = useState({});
  const [areAllParamsValid, setAreAllParamsValid] = useState(false);
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [dropDownReportTypeList, setDropDownReportTypeList] = useState([]);
  const [selectedReportType, setSelectedReportType] = useState([]);

  const requiredParams = ['invoice_start_date', 'invoice_end_date', 'risk_scores', 'processing_at_start_date', 'processing_at_end_date'];

  const { INVOICE_REPORT_GENERATION_WITH_DELAY: displayUserNote } = useSelector((state) => state.core.applicationConfigValues);

  const handleInvDateRangeChange = (selected) => {
    const params = { ...pendingParams };
    params.invoice_start_date = selected.startDate;
    params.invoice_end_date = selected.endDate;
    setPendingParams(params);
  };

  const handleProcessingDateRangeChange = (selected) => {
    const params = { ...pendingParams };
    params.processing_at_start_date = selected.startDate;
    params.processing_at_end_date = selected.endDate;
    setPendingParams(params);
  };

  const handleRiskLevelSelection = (selected) => {
    const params = { ...pendingParams };
    params.risk_scores = selected;
    setPendingParams(params);
  };

  const validateInvoiceDateRange = (key) => {
    switch (key) {
      case 'risk_scores':
        return isArrayWithData(pendingParams[key]);
      case 'invoice_start_date':
      case 'invoice_end_date':
        return isDefined(pendingParams[key]);
      default:
        return true;
    }
  };

  const validateProcessingDateRange = (key) => {
    switch (key) {
      case 'risk_scores':
        return isArrayWithData(pendingParams[key]);
      case 'processing_at_start_date':
      case 'processing_at_end_date':
        return isDefined(pendingParams[key]);
      default:
        return true;
    }
  };

  const handleRedirectToMMAnalytics = () => {
    const { navigationRedirectURLMapping } = apiEndPointHostNames;
    const url = navigationRedirectURLMapping(
      window.location.hostname,
      '/insights/dashboard/list',
    );
    window.location.href = url;
  };

  const isRequestValid = () => {
    const isInvDateRngValid = requiredParams
                                .map((param) => validateInvoiceDateRange(param))
                                .every((item) => item);
    const isProcessingDateRngValid = requiredParams
                                .map((param) => validateProcessingDateRange(param))
                                .every((item) => item);
    setAreAllParamsValid(isInvDateRngValid || isProcessingDateRngValid);
  };

  const handleSelectedReportType = (selected) => {
    setSelectedReportType(selected)
  }

  useEffect(isRequestValid, [pendingParams]);

  useEffect(() => {
    apiAAAReports.getAPlistingreports(userOrganizationId)
    .then(response => {
      if (response?.status === 200 && response?.data?.length) {
        const reportListingAP = response.data.filter(report => report?.report_category === 'REPORT_CATEGORY_AAA');
        const reportList = createDropdownDataObj(reportListingAP, 'report_code', 'description');
        setDropDownReportTypeList(reportList);
      } else {
        setDropDownReportTypeList([]);
        const errorAction = getNotificationAction(
          "error",
          formatMessage({ id: "global.error" }),
          formatMessage({
            id: "aaaReports.api.GET_RECORD_TYPE_ERROR",
          }),
          true,
          3000
        );
        dispatch(errorAction);
      }
    })
    .catch(() => {
      setDropDownReportTypeList([]);
      const errorAction = getNotificationAction(
        "error",
        formatMessage({ id: "global.error" }),
        formatMessage({
          id: "aaaReports.api.GET_RECORD_TYPE_ERROR",
        }),
        true,
        3000
      );
      dispatch(errorAction);
    })
  }, []);

  return (
    <AzDialog
      open
      handleClose={handleCloseModal}
      other={{ maxWidth: 'sm', fullWidth: true, scroll: 'paper' }}
    >
      <AzDialogCloseButton handleClose={handleCloseModal} />
      <AzDialogTitle>
        <Title>{formatMessage({ id: 'aaaReports.text.GENERATE_REPORT' })}</Title>
      </AzDialogTitle>
      <AzDialogContent>
        <DropDownList>
          <Autocomplete
              isSingleSelect
              closeDropdownOnSelect
              allowClear={false}
              options={dropDownReportTypeList}
              selected={selectedReportType}
              handleChange={(e) => (handleSelectedReportType(e))}
              placeholder="Select report generation type"
            />
        </DropDownList>
        {selectedReportType[0]?.id === 'INVOICES_BY_DATE_RANGE' &&
          <ContentWrapper>
          <ParamSection>
            <Label>{formatMessage({ id: 'aaaReports.text.INVOICE_DATE_RANGE' })}</Label>
            <DateRangePicker
              endDate={pendingParams.invoice_end_date}
              maxDate={moment.now()}
              onChange={(e) => (handleInvDateRangeChange(e))}
              startDate={pendingParams.invoice_start_date}
            />
          </ParamSection>
          <ParamSection>
            <Label>{formatMessage({ id: 'aaaReports.text.INVOICE_PROCESSING_AT_RANGE' })}</Label>
            <DateRangePicker
              startDate={pendingParams.processing_at_start_date}
              endDate={pendingParams.processing_at_end_date}
              maxDate={moment.now()}
              onChange={(e) => (handleProcessingDateRangeChange(e))}
            />
          </ParamSection>
          <ParamSection>
            <Label required>Invoice State</Label>
            <Autocomplete
              isSingleSelect
              closeDropdownOnSelect
              options={dropDownOptions}
              selected={isDefined(pendingParams.risk_scores) ? pendingParams.risk_scores : []}
              handleChange={(e) => (handleRiskLevelSelection(e))}
              placeholder="Invoice State"
            />
          </ParamSection>
          {(displayUserNote === "TRUE") && <Note>Invoices submitted in the past 60 mins may not be available in the generated report.</Note>}
        </ContentWrapper>
        }
      </AzDialogContent>
      <AzDialogActions>
        <Button
          dataAzLabel="Cancel"
          classes="button--no-outline"
          clicked={handleCloseModal}
        >
          {formatMessage({ id: 'aaaReports.text.CANCEL' })}
        </Button>
        <Button
          dataAzLabel="Create"
          classes="button--brand"
          clicked={() => generateReport(pendingParams, selectedReportType)}
          isDisabled={
            !(
              areAllParamsValid
              || selectedReportType[0]?.id === "EMPLOYEE_DETAILS_REPORT_AAA"
            ) || selectedReportType[0]?.id === "INVOICES_BY_DATE_RANGE"
          }
        >
          {formatMessage({ id: 'aaaReports.text.CREATE' })}
        </Button>
      </AzDialogActions>
      {selectedReportType[0]?.id === "INVOICES_BY_DATE_RANGE" && (
        <DisclaimerContainer>
          <StyledWarningIcon />
          <div>
            <DisclaimerText>
              This report is replaced with the new “AP Consolidated Report” in
              Mastermind Analytics which provides improved filtering, richer
              data and other enhancements. Please use the new report going
              forward.
            </DisclaimerText>
            <DisclaimerLinks>
              <Link href="https://support.appzen.com/hc/en-us/articles/20346767145107-AP-Consolidated-Report-Dashboard" target="_blank">
                Learn More
              </Link>
              <RightLink onClick={handleRedirectToMMAnalytics}>
                Go to Mastermind Analytics
              </RightLink>
            </DisclaimerLinks>
          </div>
        </DisclaimerContainer>
      )}
    </AzDialog>
  );
}

ReportGenerationModal.propTypes = {
  generateReport: PropTypes.func,
  handleCloseModal: PropTypes.func,
};

ReportGenerationModal.defaultProps = {
  generateReport: null,
  handleCloseModal: null,
};

export default ReportGenerationModal;
