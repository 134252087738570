import { takeLatest, call, put } from 'redux-saga/effects';
import { localizationConfig } from '@az/common-configs';
import * as actions from '../../store/actions/actionTypes';
import apiUser from './api-user';
import { getNotificationAction } from '../../store/actions/action-coreService';
import { AP } from '../../config/localization';

const { global } = localizationConfig;

function* getRolesByProduct(payload) {
  try {
    const product = payload.data;
    const response = yield call(apiUser.getRolesByProduct, product);
    if (response.status === 200) {
      const actionLookup = {
        all: actions.GET_ALL_ROLES_SUCCESS,
        ap: actions.GET_ALL_AP_ROLES_SUCCESS,
      };
      yield put({
        type: actionLookup[product],
        payload: {
          data: response.data,
        },
      });
    } else {
      const errorMsg = (response.data && response.data.msg) ? response.data.msg
        : AP.userManagement.api.GET_ROLES_ERROR;
      const errorAction = getNotificationAction('error', global.error, errorMsg, true, 3000);
      yield put(errorAction);
    }
  } catch (e) {
    const errorAction = getNotificationAction('error', global.error, AP.userManagement.api.GET_ROLES_ERROR, true, 3000);
    yield put(errorAction);
  }
}

const userSaga = [
  takeLatest(actions.GET_ROLES_BY_PRODUCT, getRolesByProduct),
];

export default userSaga;
