import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { alertReducer, notificationsReducer } from '@az/components';

import authReducer from '../../featureComponents/Login/LoginWrapper/reducers-login';
import changePasswordReducer from '../../featureComponents/User/ChangePasswordDialog/reducer-changePassword';
import coreServiceReducer from './reducer-coreService';
import requestPasswordResetReducer from '../../featureComponents/Login/LoginWrapper/reducers-forgotPassword';
import resetPasswordReducer from '../../featureComponents/Login/LoginWrapper/reducers-resetPassword';
import uiReducer from './reducer-ui';
import userReducer from '../../featureComponents/User/reducer-user';

const appReducer = (history) => {
  const reducers = combineReducers({
    alert: alertReducer,
    auth: authReducer,
    core: coreServiceReducer,
    generic: combineReducers({
      requestPasswordReset: requestPasswordResetReducer,
      resetPassword: resetPasswordReducer,
      changePassword: changePasswordReducer,
    }),
    notificationsReducer,
    router: connectRouter(history),
    ui: uiReducer,
    userReducer,
  });

  const rootReducer = (state, action) => {
    let newState = state;
    if (action.type === 'RESET_APP') {
      // resets all reducers, except for the router reducer
      const { router } = newState;
      newState = { router };
    }
    return reducers(newState, action);
  };

  return rootReducer;
};

export default appReducer;
