import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './saga/index';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import createRootReducer from '../store/reducers';
import UserConfirmation from '../Layout/UserConfirmationModal/UserConfirmation';

export const history = createBrowserHistory({
  getUserConfirmation: UserConfirmation,
});

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

export default function configureStore(preloadedState) {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
      ),
    ),
  );

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createRootReducer(history));
    });
  }

  // Run the saga
  sagaMiddleware.run(rootSaga);

  return store;
}
