import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { uiConfig } from '@az/common-configs';

const { COLOR } = uiConfig;

const HeaderRow = styled.div`
  align-items: start;
  border-bottom: 0.5px solid ${COLOR.DEFAULTS.LINE_BORDER};
  display: flex;
  padding: 5px 5px 10px 5px;
`;

const HeaderCol = styled.div`
  color: ${COLOR.DEFAULTS.LABEL_TEXT};
  display: flex;
  font-size: 11px;
  font-weight: 400;
  padding: 0px 20px 2px 0px;
  text-align: start;
  flex: ${({ colWidth }) => {
    switch (colWidth) {
      case 'sm':
        return '1';
      case 'md':
        return '2';
      case 'lg':
        return '3';
      default:
        return '1';
    }
  }};
`;

function ReportGenerationHeader() {
  const { formatMessage } = useIntl();
  return (
    <HeaderRow data-az-label="AAA Report Generation Table Header">
      <HeaderCol data-az-label="Report Type" colWidth="lg">{formatMessage({ id: 'aaaReports.text.REPORT_TYPE' })}</HeaderCol>
      <HeaderCol data-az-label="Creation Time" colWidth="lg">{formatMessage({ id: 'aaaReports.text.CREATION_TIME' })}</HeaderCol>
      <HeaderCol data-az-label="Status" colWidth="sm">{formatMessage({ id: 'aaaReports.text.STATUS' })}</HeaderCol>
      <HeaderCol data-az-label="Fail Reason" colWidth="lg">{formatMessage({ id: 'aaaReports.text.FAIL_REASON' })}</HeaderCol>
      <HeaderCol data-az-label="Action" colWidth="md">{formatMessage({ id: 'aaaReports.text.ACTION' })}</HeaderCol>
    </HeaderRow>
  );
}

export default ReportGenerationHeader;
