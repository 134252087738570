import { fetcher } from '../../store/service/helper';
import api from '../../store/service/api';
import qs from 'qs';

export const userService = {
  changePassword(currentPassword, newPassword) {
    const requestUrl = 'rest/resetpassword/updatePassword';
    const data = {
      oldPassword: currentPassword,
      newPassword: newPassword
    };

    const config = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: qs.stringify(data)
    };

    return fetcher(`${api.expenseBaseUrl}${requestUrl}`, config)
      .then(res => res)
      .catch(error => error);
  },

};