import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { uiConfig } from '@az/common-configs';
import { downloadFile, isDefined } from '@az/utility';
import { Pagination } from '@az/components';
import ReportGenerationHeader from './ReportGenerationHeader';
import ReportGenerationRow from './ReportGenerationRow';
import apiAAAReports from '../api-aaaReports';

const { COLOR } = uiConfig;

const Wrapper = styled.div`
  background: ${COLOR.WHITE};
  border: 0.5px solid ${COLOR.DEFAULTS.LINE_BORDER};
  border-radius: 5px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
  overflow: auto;
  padding: 20px;
  width: 100%;
`;

const PaginationWrapper = styled.div`
  padding: 15px 0;
`;

function ReportGenerationTable({
  currentPage = 1,
  handleItemsPerPageChange,
  handlePageChange,
  handleRefreshReport,
  handleRetryReport,
  metadata = {},
  pageLimit = 10,
  reports = [],
}) {
  const handleDownloadReport = (reportFormat, reportId, s3Key) => {
    apiAAAReports.downloadAAAReport(s3Key)
      .then((response) => {
        downloadFile(`${reportId}.zip`, response);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  return (
    <Wrapper data-az-label="AAA Report Generation Table">
      <ReportGenerationHeader />
      {
        reports.map((report, index) => (
          <ReportGenerationRow
            handleDownloadReport={handleDownloadReport}
            handleRefreshReport={handleRefreshReport}
            handleRetryReport={handleRetryReport}
            key={index}
            report={report}
          />
        ))
      }
      <PaginationWrapper>
        {isDefined(metadata) && isDefined(metadata.total_number_reports) && (
          <Pagination
            itemsPerPage={pageLimit}
            currentPage={currentPage}
            onItemsPerPageChange={(e) => (handleItemsPerPageChange(e))}
            onSwitchPage={(e) => (handlePageChange(e))}
            totalNumberItems={metadata.total_number_reports}
          />
        )}
      </PaginationWrapper>
    </Wrapper>
  );
}

ReportGenerationTable.propTypes = {
  currentPage: PropTypes.number,
  handleRefreshReport: PropTypes.func,
  handleRetryReport: PropTypes.func,
  handleItemsPerPageChange: PropTypes.func,
  handlePageChange: PropTypes.func,
  metadata: PropTypes.object,
  pageLimit: PropTypes.number,
  reports: PropTypes.array,
};

ReportGenerationTable.defaultProps = {
  currentPage: 1,
  handleItemsPerPageChange: null,
  handlePageChange: null,
  handleRefreshReport: null,
  handleRetryReport: null,
  metadata: {},
  pageLimit: 10,
  reports: [],
};

export default ReportGenerationTable;
