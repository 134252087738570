import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Button, PageHeader } from '@az/components';
import {
  formatDocumentTitle, generateObjForPageChange, restrictPageView, isArrayWithData, isDefined
} from '@az/utility';
import { globalConfig } from '@az/common-configs';
import ReportGenerationTable from './ReportGenerationTable';
import ReportGenerationModal from './ReportGenerationModal';
import apiAAAReports from '../api-aaaReports';
import { getNotificationAction } from '../../../store/actions/action-coreService';

const { isoDateFormat } = globalConfig;

const Wrapper = styled.div`
  padding: 30px 34px;
  width: 95%;
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const Body = styled.div`
  margin-top: 15px;
  overflow: auto;
`;

function AAAReportGeneration() {
  const intl = useIntl();
  const dispatch = useDispatch();

  const coreReducer = useSelector(({ core }) => core);
  const { userDetails: { az_user_id: userId } } = coreReducer;

  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [metadata, setMetadata] = useState({});
  const [pageLimit, setPageLimit] = useState(10);
  const [reports, setReports] = useState([]);
  const [userOrganizationId, setUserOrganizationId] = useState(null);

  const formatPayloadToCreateReport = (params) => {
    const payload = {
      risk_scores: [],
      appzen_invoice_state: isArrayWithData(params.risk_scores) ? params.risk_scores[0].id : '',
      is_all_orgs_and_policies_selected: false,
      is_all_auditors_selected: false,
      loggedInUserOrgId: `${userOrganizationId}`,
    };

    if (params.invoice_start_date && params.invoice_end_date) {
      payload.invoice_start_date = params.invoice_start_date.format(isoDateFormat);
      payload.invoice_end_date = params.invoice_end_date.format(isoDateFormat);
    }

    if (params.processing_at_start_date && params.processing_at_end_date) {
      payload.processing_at_start_date = params.processing_at_start_date.format(isoDateFormat);
      payload.processing_at_end_date = params.processing_at_end_date.format(isoDateFormat);
    }
    return payload;
  };

  const handleItemsPerPageChange = (itemsPerPage) => {
    setPageLimit(Number(itemsPerPage));
    setCurrentPage(1);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(Number(pageNumber));
  };

  const retrieveAAAReports = (orgId = userOrganizationId) => {
    const { offset } = generateObjForPageChange(currentPage, pageLimit);
    apiAAAReports.getAAAReports({ length: pageLimit, start: offset, searcb: null, orgId })
    .then((response) => {
      if (response.status === 200) {
        const { iTotalDisplayRecords, aaData } = response.data;
        const aaaReports = aaData.map((aaaReport) => {
          const { invoice_start_date, invoice_end_date, processing_at_start_date, processing_at_end_date, appzen_invoice_state } = JSON.parse(aaaReport.params);
          return {
            ...aaaReport,
            ...{
              created_at: aaaReport.creation_time,
              parameters: { invoice_start_date, invoice_end_date, processing_at_start_date, processing_at_end_date, appzen_invoice_state },
            },
          };
        });
        setReports(aaaReports);
        setMetadata({ total_number_reports: iTotalDisplayRecords });
      } else {
        const errorMsg = response.data && response.data.msg ? response.data.msg : intl.formatMessage({ id: 'aaaReports.api.GET_AAA_REPORTS_ERROR' });
        const errorAction = getNotificationAction('error', intl.formatMessage({ id: 'global.error' }), errorMsg, true, 3000);
        dispatch(errorAction);
      }
    })
    .catch(() => {
      const errorAction = getNotificationAction('error', intl.formatMessage({ id: 'global.error' }), intl.formatMessage({ id: 'aaaReports.api.GET_AAA_REPORTS_ERROR' }), true, 3000);
      dispatch(errorAction);
    });
  };

  const handleRetryReport = (reportId) => {
    apiAAAReports.retryReport(reportId)
      .then((response) => {
        if (response.status === 200 && response.data) {
          retrieveAAAReports();
        } else {
          const errorMsg = response.data && response.data.msg ? response.data.msg : intl.formatMessage({ id: 'aaaReports.api.REFRESH_REPORT_ERROR' });
          const errorAction = getNotificationAction('error', intl.formatMessage({ id: 'global.error' }), errorMsg, true, 3000);
          dispatch(errorAction);
        }
      })
      .catch(() => {
        const errorAction = getNotificationAction('error', intl.formatMessage({ id: 'global.error' }), intl.formatMessage({ id: 'aaaReports.api.REFRESH_REPORT_ERROR' }), true, 3000);
        dispatch(errorAction);
      });
  };

  const handleRefreshReport = ((reportId) => {
    apiAAAReports.refreshReport(reportId)
      .then((response) => {
        if (response.status === 200 && response.data) {
          const refreshedReport = response.data;
          const refreshedReports = reports.map((report) => {
            if (report.report_id === reportId) {
              const { invoice_start_date, invoice_end_date, processing_at_start_date, processing_at_end_date, appzen_invoice_state } = JSON.parse(refreshedReport.params);
              return {
                ...refreshedReport,
                ...{
                  created_at: refreshedReport.creation_time,
                  parameters: { invoice_start_date, invoice_end_date, processing_at_start_date, processing_at_end_date, appzen_invoice_state },
                },
              };
            }
            return report;
          });
          setReports(refreshedReports);
        } else {
          const errorMsg = response.data && response.data.msg ? response.data.msg : intl.formatMessage({ id: 'aaaReports.api.REFRESH_REPORT_ERROR' });
          const errorAction = getNotificationAction('error', intl.formatMessage({ id: 'global.error' }), errorMsg, true, 3000);
          dispatch(errorAction);
        }
      })
      .catch(() => {
        const errorAction = getNotificationAction('error', intl.formatMessage({ id: 'global.error' }), intl.formatMessage({ id: 'aaaReports.api.REFRESH_REPORT_ERROR' }), true, 3000);
        dispatch(errorAction);
      });
  });

  const generateReport = (params, selectedReportType) => {
    if (selectedReportType[0]?.id === 'EMPLOYEE_DETAILS_REPORT_AAA') {
      apiAAAReports.generateEmployeeDetailsAPReport()
      .then(response => {
        if (response?.status === 200 && response?.data?.success === "true") {
          setIsModalOpen(false);
          setCurrentPage(1);
          const successMsg = intl.formatMessage({ id: 'aaaReports.api.GENERATE_EMPLOYEE_REPORT_SUCCESS' });
          const successAction = getNotificationAction('success', intl.formatMessage({ id: 'global.success' }), successMsg, true, 3000);
          dispatch(successAction);
        } else {
          const errorMsg = response.data && response.data.msg ? response.data.msg : intl.formatMessage({ id: 'aaaReports.api.GENERATE_REPORT_ERROR' });
          const errorAction = getNotificationAction('error', intl.formatMessage({ id: 'global.error' }), errorMsg, true, 3000);
          dispatch(errorAction);
        }
      })
      .then(() => {
        retrieveAAAReports();
      })
      .catch(() => {
        const errorAction = getNotificationAction('error', intl.formatMessage({ id: 'global.error' }), intl.formatMessage({ id: 'aaaReports.api.GENERATE_REPORT_ERROR' }), true, 3000);
        dispatch(errorAction);
      })
    } else if (selectedReportType[0]?.id === 'INVOICES_BY_DATE_RANGE') {
      const payload = formatPayloadToCreateReport(params);
      apiAAAReports.createAAAReport(payload)
      .then((response) => {
        if (response.status === 200) {
          setIsModalOpen(false);
          setCurrentPage(1);
          const successMsg = intl.formatMessage({ id: 'aaaReports.api.GENERATE_REPORT_SUCCESS' });
          const successAction = getNotificationAction('success', intl.formatMessage({ id: 'global.success' }), successMsg, true, 3000);
          dispatch(successAction);
        } else {
          const errorMsg = response.data && response.data.msg ? response.data.msg : intl.formatMessage({ id: 'aaaReports.api.GENERATE_REPORT_ERROR' });
          const errorAction = getNotificationAction('error', intl.formatMessage({ id: 'global.error' }), errorMsg, true, 3000);
          dispatch(errorAction);
        }
      })
      .then(() => {
        retrieveAAAReports();
      })
      .catch(() => {
        const errorAction = getNotificationAction('error', intl.formatMessage({ id: 'global.error' }), intl.formatMessage({ id: 'aaaReports.api.GENERATE_REPORT_ERROR' }), true, 3000);
        dispatch(errorAction);
      });
    }
  };

  const onInit = (orgId) => {
    restrictPageView(coreReducer.isConcurPartnerAdmin);
    document.title = formatDocumentTitle(intl.formatMessage({ id: 'aaaReports.text.AAA_REPORT_GENERATION' }));
    retrieveAAAReports(orgId);
  };

  useEffect(() => {
    if (userId && !userOrganizationId) {
      apiAAAReports.getUserOrganizations()
        .then((response) => {
          setUserOrganizationId(response.data.default);
          onInit(response.data.default);
        });
    }
    return () => { };
  }, [userId, userOrganizationId]);


  useEffect(retrieveAAAReports, [currentPage, pageLimit]);

  return (
    <Wrapper>
      <Header>
        <PageHeader title={intl.formatMessage({ id: 'aaaReports.text.AAA_REPORT_GENERATION' })} />
        <Button classes="button--brand" dataAzLabel="Generate Report" clicked={handleOpenModal}>{intl.formatMessage({ id: 'aaaReports.text.GENERATE_REPORT' })}</Button>
      </Header>
      <Body>
        <ReportGenerationTable
          currentPage={currentPage}
          handleItemsPerPageChange={handleItemsPerPageChange}
          handlePageChange={handlePageChange}
          handleRefreshReport={handleRefreshReport}
          handleRetryReport={handleRetryReport}
          metadata={metadata}
          pageLimit={pageLimit}
          reports={reports}
          isAAAReport
        />
      </Body>
      {
        isModalOpen && (
          <ReportGenerationModal
            generateReport={generateReport}
            handleCloseModal={handleCloseModal}
            userOrganizationId={userOrganizationId}
          />
        )
      }
    </Wrapper>
  );
}

export default AAAReportGeneration;
