import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { uiConfig } from '@az/common-configs';
import { ExitToAppRounded } from '@material-ui/icons';
import {
  AzDialog,
  AzDialogCloseButton,
  AzDialogTitle,
  AzDialogContent,
  AzDialogActions,
  Button,
} from '@az/components';

const { COLOR } = uiConfig;

const StyledTitle = styled.div`
  color: ${COLOR.DEFAULTS.BODY_TEXT};
  display: flex;
  font-size: 18px;
  justify-content: center;
  letter-spacing: .28px;
`;

const StyledContent = styled.div`
  align-items: center;
  color: ${COLOR.DEFAULTS.BODY_TEXT};
  display: flex;
  flex-direction: column;
`;

const StyledIcon = styled(ExitToAppRounded)`
  color: ${COLOR.DEFAULTS.ICON};
  margin-bottom: 8px;
  &[role=presentation] {
    font-size: 32px;
  }
`;

const StyledParagraph = styled.p`
  color: ${COLOR.DEFAULTS.LABEL_TEXT};
  font-size: 14px;
  letter-spacing: .29px;
  line-height: 19px;
  padding: 5px 60px;
  text-align: center;
`;

const StyledActions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

function SessionExpiredDialog({ isOpen, onClose }) {
  const intl = useIntl();
  return (
    <AzDialog
      open={isOpen}
      handleClose={onClose}
      other={{
        maxWidth: 'sm',
        fullWidth: true,
      }}
    >
      <AzDialogCloseButton handleClose={onClose} />
      <AzDialogTitle>
        <StyledTitle>
          {intl.formatMessage({ id: 'global.sessionExpiredDialog.TITLE' })}
        </StyledTitle>
      </AzDialogTitle>
      <AzDialogContent>
        <StyledContent>
          <StyledIcon />
          <StyledParagraph>
            {intl.formatMessage({ id: 'global.sessionExpiredDialog.BODY_MESSAGE' })}
          </StyledParagraph>
        </StyledContent>
      </AzDialogContent>
      <AzDialogActions>
        <StyledActions>
          <Button
            classes="button button--brand"
            clicked={onClose}
            elementAttributes={{
              'data-az-label': 'Acknowledge Session Expiration',
              role: 'button',
            }}
          >
            {intl.formatMessage({ id: 'global.sessionExpiredDialog.BUTTON' })}
          </Button>
        </StyledActions>
      </AzDialogActions>
    </AzDialog>
  );
}

SessionExpiredDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

SessionExpiredDialog.defaultProps = {
  isOpen: false,
};

export default SessionExpiredDialog;
