/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { uiConfig } from '@az/common-configs';
import { AccessAlarmRounded } from '@material-ui/icons';
import {
  AzDialog,
  AzDialogCloseButton,
  AzDialogTitle,
  AzDialogContent,
  AzDialogActions,
  Button,
} from '@az/components';

const { COLOR, DEFAULT_SESSION_COUNTDOWN_TIME_IN_SECONDS } = uiConfig;

const StyledTitle = styled.div`
  color: ${COLOR.DEFAULTS.BODY_TEXT};
  display: flex;
  font-size: 18px;
  justify-content: center;
  letter-spacing: .28px;
  width: 100%;
`;

const StyledContent = styled.div`
  align-items: center;
  color: ${COLOR.DEFAULTS.BODY_TEXT};
  display: flex;
  flex-direction: column;
`;

const StyledIcon = styled(AccessAlarmRounded)`
  color: ${COLOR.DEFAULTS.ICON};
  margin-bottom: 8px;
  &[role=presentation] { font-size: 32px }
`;

const StyledCounter = styled.div`
  color: ${COLOR.DEFAULTS.BODY_TEXT};
  font-size: 32px;
  letter-spacing: .38px;
  margin-bottom: 32px;
`;

const StyledParagraph = styled.p`
  color: ${COLOR.DEFAULTS.LABEL_TEXT};
  font-size: 14px;
  letter-spacing: .29px;
  line-height: 19px;
  padding: 5px 60px;
  text-align: center;
  width: 100%;
`;

function SessionCountdownDialog({
  isOpen,
  onExtendSession,
  onLogout,
}) {
  const [
    remainingTimeInSeconds,
    setRemainingTimeInSeconds,
  ] = useState(DEFAULT_SESSION_COUNTDOWN_TIME_IN_SECONDS);
  const intl = useIntl();
  let countdownInterval;

  const formatDisplayTime = (numSeconds) => {
    const minutes = Math.floor(numSeconds / 60);
    const seconds = Math.ceil(numSeconds % 60);
    return `${minutes}:${seconds <= 9 ? '0' : ''}${seconds}`;
  };

  useEffect(() => {
    if (isOpen) {
      const startTime = new Date();
      setRemainingTimeInSeconds(DEFAULT_SESSION_COUNTDOWN_TIME_IN_SECONDS);
      countdownInterval = setInterval(() => {
        const currentTime = new Date();
        const deltaInSec = Math.floor((+currentTime - +startTime) / 1000);
        setRemainingTimeInSeconds(DEFAULT_SESSION_COUNTDOWN_TIME_IN_SECONDS - deltaInSec);
      }, 1000);
    } else {
      clearInterval(countdownInterval);
      setRemainingTimeInSeconds(DEFAULT_SESSION_COUNTDOWN_TIME_IN_SECONDS);
    }
    return () => {
      clearInterval(countdownInterval);
      setRemainingTimeInSeconds(DEFAULT_SESSION_COUNTDOWN_TIME_IN_SECONDS);
    };
  }, [isOpen]);

  useEffect(() => {
    if (remainingTimeInSeconds <= 0) {
      sessionStorage.setItem('SessionExpired', 'true');
      onLogout();
    }
  }, [remainingTimeInSeconds]);

  return (
    <AzDialog
      open={isOpen}
      handleClose={onExtendSession}
      other={{
        maxWidth: 'sm',
        fullWidth: true,
      }}
    >
      <AzDialogCloseButton handleClose={onExtendSession} />
      <AzDialogTitle>
        <StyledTitle>
          {intl.formatMessage({ id: 'session.sessionCountdownDialog.headings.MODAL_HEADING' })}
        </StyledTitle>
      </AzDialogTitle>
      <AzDialogContent>
        <StyledContent>
          <StyledIcon />
          <StyledCounter>
            {formatDisplayTime(remainingTimeInSeconds)}
          </StyledCounter>
          <StyledParagraph>
            {intl.formatMessage({ id: 'session.sessionCountdownDialog.bodyText.MESSAGE' })}
          </StyledParagraph>
        </StyledContent>
      </AzDialogContent>
      <AzDialogActions>
        <Button
          classes="button button--no-outline"
          clicked={onLogout}
          elementAttributes={{
            'data-az-label': 'Logout for Session Countdown',
            role: 'button',
          }}
        >
          {intl.formatMessage({ id: 'session.sessionCountdownDialog.buttonText.LOGOUT' })}
        </Button>
        <Button
          classes="button button--brand"
          clicked={onExtendSession}
          elementAttributes={{
            'data-az-label': 'Extend Session',
            role: 'button',
          }}
        >
          {intl.formatMessage({ id: 'session.sessionCountdownDialog.buttonText.STAY_SIGNED_IN' })}
        </Button>
      </AzDialogActions>
    </AzDialog>
  );
}

SessionCountdownDialog.propTypes = {
  isOpen: PropTypes.bool,
  onExtendSession: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

SessionCountdownDialog.defaultProps = {
  isOpen: false,
};

export default SessionCountdownDialog;
