import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { localizationConfig, localConfig } from '@az/common-configs';
import { isDefined, determineRedirect } from '@az/utility';
import qs from 'query-string';
import './LoginWrapper.scss';
import AppZenLogoImg from '../../../assets/images/appzen-logo-dark.svg';
import { AlertMessage, LoadingIndicator, showAlert } from '@az/components';
import * as actions from '../../../store/actions/actionTypes';
import SignInForm from './SignInForm/SignInForm';
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm';
import ResetPasswordForm from './ResetPasswordForm/ResetPasswordForm';

const { global } = localizationConfig;
const { apiServer } = localConfig;

const mapStateToProps = state => {
  const {
    userLoginInProgress,
    userInfo,
    userLogoutComplete
  } = state.auth;

  const { variant, dismissible, show, message, autoClose } = state.alert;
  const { requestResetInProgress, requestResetComplete, error: requestResetError } = state.generic.requestPasswordReset;
  const { resetPasswordInProgress, resetComplete, error: resetPasswordError } = state.generic.resetPassword;

  return {
    auth: {
      userLoginInProgress,
      userInfo,
      userLogoutComplete
    },
    alert: {
      variant,
      dismissible,
      show,
      message,
      autoClose
    },
    requestPasswordReset: {
      requestResetInProgress, requestResetComplete, error: requestResetError
    },
    resetPassword: {
      resetPasswordInProgress, resetComplete, error: resetPasswordError
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showErrorMessage: (message) => {
      dispatch(showAlert(message, 'error'));
    },
    submitLogin: (data) => {
      dispatch({
        type: actions.LOGIN_SUBMIT,
        data: { ...data }
      });
    },
    signInWithSSO: (data) => {
      dispatch({
        type: actions.SSO_SUBMIT,
        data: { ...data }
      });
    },
    submitRequestPasswordReset: data => {
      dispatch({
        type: actions.REQUEST_PASSWORD_RESET,
        data: { ...data }
      });
    },
    submitResetPassword: data => {
      dispatch({
        type: actions.RESET_PASSWORD,
        data: { ...data }
      });
    }
  };
};

class LoginWrapper extends Component {
  state = {
    showSSOLink: true,
    showPasswordField: false,
    useDirectSSO: false,
    username: undefined,
    greetingsMsg: 'Welcome back.',
    urlParams: {}
  };

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const isAmazon = this.getLoginSource(urlString) === 'amazon';
    const isCloud1OrQA = this.getLoginSource(urlString) === 'cloud1OrQa';
    const usernameValue = url.searchParams.get('username');

    this.reserveUrlParams(urlString);

    if (isAmazon) {
      this.setState({
        useDirectSSO: true,
        showSSOLink: false
      });
    } else {
      this.setState({
        showPasswordField: !isCloud1OrQA || (isCloud1OrQA && isDefined(usernameValue)),
        username: usernameValue
      });
    }

    this.generateGreetings();

    if (this.props.location.pathname.includes('reset-password')) {
      this.getUrlParams();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.generateGreetings();
      this.props.showErrorMessage('');

      if (this.props.location.pathname.includes('reset-password')) {
        this.getUrlParams();
      }
    }
  }

  getUrlParams = () => {
    const urlParams = qs.parse(this.props.location.search);
    this.setState({ urlParams });
  };

  generateGreetings = () => {
    let view;

    if (this.props.location.pathname.includes('request-password-reset')) {
      view = 'FORGOT_PASSWORD';
    } else if (this.props.location.pathname.includes('reset-password')) {
      view = 'RESET_PASSWORD';
    } else {
      view = 'SIGN_IN';
    }

    this.setState({
      greetingsMsg: global.greetingsMessages[view],
      greetingsSubtext: global.greetingsSubtext[view]
    });
  };

  getLoginSource = (url = '') => {
    const urlToLowerCase = url.toLowerCase();

    if (urlToLowerCase.indexOf('//a.appzen.com') > -1 || urlToLowerCase.indexOf('amazon.appzen.com') > -1 || urlToLowerCase.indexOf('amz.appzen.com') > -1) {
      return 'amazon';
    }

    if (urlToLowerCase.indexOf('cloud1') > -1 || urlToLowerCase.indexOf('/qa.appzen.com') > -1) {
      return 'cloud1OrQa';
    }

    return 'default';
  };

  handleSubmitSignInForm = (event, loginForm) => {
    if (this.state.showPasswordField) {
      if (!isDefined(loginForm.username.value) || !isDefined(loginForm.password.value)) {
        this.props.showErrorMessage(global.notificationMessages.EMPTY_LOGIN_ID);
      } else {
        loginForm.formIsValid = true;
        const urlParams = qs.parse(this.props.location.search);

        this.props.submitLogin({
          'username': loginForm.username.value,
          'password': loginForm.password.value,
          'rememberMe': loginForm.rememberMe.value,
          redirectUrl: urlParams.redirectUrl || '',
        });

      }
    } else {
      const clientUrl = (apiServer === '') ? window.location.href : apiServer;
      const redirectUrl = determineRedirect(loginForm.username.value, clientUrl);
      if (redirectUrl.length > 0) {
        window.location.assign(redirectUrl + '?username=' + loginForm.username.value);
      } else {
        this.setState({
          showPasswordField: true,
          showSSOLink: false
        });
      }
    }
  };

  handleSubmitForgotPasswordForm = (e, forgotPasswordForm) => {
    if (!isDefined(forgotPasswordForm.username.value)) {
      this.props.showErrorMessage(global.notificationMessages.INVALID_LOGIN_EMAIL);
    } else {
      forgotPasswordForm.formIsValid = true;

      this.props.submitRequestPasswordReset({
        username: forgotPasswordForm.username.value
      });
    }
  };

  handleSubmitResetPasswordForm = (e, resetPasswordForm) => {
    this.props.submitResetPassword({
      az_user_id: this.state.urlParams.userId,
      password: resetPasswordForm.newPassword.value,
      token: this.state.urlParams.token
    });
  };

  /**
   * Single sign on handler
   */
  handleSSO = (e, username) => {
    let domainName;

    if (isDefined(username)) {
      if (username === 'amazonsso') {
        domainName = 'amazon.com';
      } else {
        domainName = username.split('@')[1];
      }

      this.props.signInWithSSO({
        username: username,
        domainName: domainName
      });
    }
  };

  reserveUrlParams = (url) => {
    const params = url.split('?')[1] || '';
    const lcParams = params.toLowerCase();
    if (lcParams.indexOf('view=manager') > -1 && lcParams.indexOf('startdate') > -1 && lcParams.indexOf('enddate') > -1) {
      localStorage.setItem('appZenUrlParams', params);
    }
  };

  renderError = () => {
    return (
      this.props.alert.message ? <AlertMessage message={this.props.alert.message}
        variant={this.props.alert.variant} /> : this.state.greetingsSubtext
    );
  };

  render() {
    let routes = <Switch>
      <Route
        path={`${this.props.match.url}/`}
        exact
        render={() => <SignInForm
          username={this.state.username}
          useDirectSSO={this.state.useDirectSSO}
          onSubmitSignInForm={this.handleSubmitSignInForm}
          onSignInWithSSO={this.handleSSO}
          showPasswordField={this.state.showPasswordField}
          showSSOLink={this.state.showSSOLink} />} />
      <Route
        path={`${this.props.match.url}/request-password-reset`}
        render={() => <ForgotPasswordForm onSubmitForgotPasswordForm={this.handleSubmitForgotPasswordForm}
          hideFields={this.props.requestPasswordReset.requestResetInProgress || (this.props.requestPasswordReset.requestResetComplete && !this.props.requestPasswordReset.error)} />} />
      <Route
        path={`${this.props.match.url}/reset-password`}
        render={() => <ResetPasswordForm onSubmitResetPasswordForm={this.handleSubmitResetPasswordForm}
          hideFields={this.props.resetPassword.resetPasswordInProgress || (this.props.resetPassword.resetComplete && !this.props.resetPassword.error)} />} />
    </Switch>;

    return (
      <div className="loginWrapper">
        <div className="loginWrapper__content">
          <div className="loginWrapper__logo">
            <a
              href="https://appzen.com"
              className="loginWrapper__logo-link"
              target="_blank"
              rel="noopener noreferrer">
              <img src={AppZenLogoImg} alt="AppZen Logo" className="logo loginWrapper__logo-img" />
            </a>
          </div>

          <div className="loginWrapper__greetings-msg">
            {this.state.greetingsMsg}
          </div>

          <div className="loginWrapper__messages">
            {this.renderError()}
          </div>

          {routes}
        </div>
        {this.props.auth.userLoginInProgress || this.props.requestPasswordReset.requestResetInProgress || this.props.resetPassword.resetPasswordInProgress ?
          <LoadingIndicator message={global.loadingMessages.GENERIC_LOADING} /> : null}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginWrapper));
