import { call, put, takeEvery } from 'redux-saga/effects';
import { localizationConfig } from '@az/common-configs';
import { isEmptyProperty, isDefined } from '@az/utility';
import { showAlert } from '@az/components';

import * as actionTypes from '../../../store/actions/actionTypes';
import { createPublicAxiosInstance } from '../../../store/service/helper';
import { resetPasswordService } from './api-password-manage';

const { global } = localizationConfig;

export const resetPasswordStart = () => {
  return {
    type: actionTypes.RESET_PASSWORD_START
  };
};

export const resetPasswordFail = (error) => {
  return {
    type: actionTypes.RESET_PASSWORD_FAILED,
    payload: error
  };
};

export const resetPasswordSuccess = (data) => {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESSFUL,
    payload: data
  };
};

function* submitResetPassword(payload) {
  let response;

  try {
    createPublicAxiosInstance();
    yield put(resetPasswordStart());

    response = yield call(resetPasswordService.resetPasswordFetchAPI, payload.data.az_user_id, payload.data.password, payload.data.token);

    if (isEmptyProperty(response) || !isDefined(response.status) || response.status >= 400) {
      const error = response ? response.data ? response.data : response : global.notificationMessages.SERVER_REQUEST_FAILED;
      yield put(resetPasswordFail(error));
      yield put(showAlert(response.data && response.data.msg ? response.data.msg : global.notificationMessages.SERVER_REQUEST_FAILED, 'error'));
    } else {
      yield put(resetPasswordSuccess(response.data));
      yield put(showAlert(global.notificationMessages.RESET_PASSWORD_SUCCESS, 'success'));
    }
  } catch (e) {
    console.log(e);
  }

}

export const resetPasswordSaga = [takeEvery(actionTypes.RESET_PASSWORD, submitResetPassword)];
